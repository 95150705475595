.head-to-head {
  border-radius: 10px;
  margin-bottom: 2rem;
}
.head-to-head.empty-widget {
  height: 10rem;
  border: 4px dashed rgba(255, 255, 255, 0.15);
  color: rgba(255, 255, 255, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
}

.head-to-head.empty-widget p {
  font-size: 1.2rem;
  line-height: 1.75rem;
}

.head-to-head .sub-title {
  display: flex;
  align-items: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #151516;
  color: #fff;
  padding: 0.5rem 1rem;
}

.head-to-head .sub-title span {
  margin-right: 0.5rem;
  color: #ffb80c;
}

.head-to-head .container {
  padding: 1rem 1rem;
  color: #151516;
  background-color: #fff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.head-to-head .television{
  width: 100%;
  display: contents;
}
