.custom-filter {
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 2rem 0;
}

.custom-filter .filter-input {
  border: 1px solid white;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  opacity: 0.2;
  width: 50%;
}

.custom-filter .filter-input .btn-search {
  background: transparent;
  height: 1.5rem;
  width: 1.5rem;
  border: none;
  padding: 0;
  margin-right: 1rem;
  background-image: url("../../media/icons/search.svg");
  background-repeat: no-repeat;
  background-position: center center;
}

.custom-filter .filter-input button {
  cursor: pointer;
}

.custom-filter .filter-input button:focus {
  outline: none;
  background: red;
  cursor: pointer;
}

.custom-filter .filter-input input {
  height: 3.5rem;
  width: 100%;
  background: transparent;
  border: none;
  color: white;
  font-size: 1rem;
  outline: none;
  opacity: 1;
}

.custom-filter .filter-input input::placeholder {
  color: white;
  font-size: 1rem;
  opacity: 1;
}

@media (max-width: 768px) {
  .custom-filter {
    height: auto;
    margin: 1rem 0;
  }
  .custom-filter .filter-input {
    height: auto;
    width: 100%;
  }
}
