.breadcrumbs {
  opacity: 0.25;
  color: #fff;
}

.breadcrumb-text {
  font-weight: 100;
}

.breadcrumb-text:hover {
  cursor: pointer;
  text-decoration: underline;
}

.breadcrumbs .breadcrumb-text,
.breadcrumbs span {
  margin-right: 0.5rem;
  text-transform: capitalize;
}
