.next-games {
  border-radius: 10px;
}

.next-games.empty-widget {
  height: 10rem;
  border: 4px dashed rgba(255, 255, 255, 0.15);
  color: rgba(255, 255, 255, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
}

.next-games.empty-widget p {
  font-size: 1.2rem;
  line-height: 1.75rem;
}

.next-games .sub-title {
  display: flex;
  align-items: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #151516;
  color: #fff;
  padding: 0.5rem 1rem;
}

.next-games .sub-title .television-icon {
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../media/icons/tv_yellow.svg");
}

.next-games .sub-title span {
  margin-right: 0.5rem;
  color: #ffb80c;
}

.next-games .container {
  padding: 1rem 1rem;
  color: #151516;
  background-color: #fff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
