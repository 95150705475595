.flag-icon {
  border-radius: 5px;
  font-size: 1.5rem;
}

.special-flag {
  display: flex;
  height: 25px;
  width: 25px;
  font-size: 1.5rem;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.default-flag {
  background-image: url("../../media/flags/default.svg");
}

.special-flag.uefa {
  background-image: url("../../media/flags/uefa.svg");
}

.special-flag.europa-league {
  background-image: url("../../media/flags/EL.svg");
}

.special-flag.nations-league {
  background-image: url("../../media/flags/UEFA_Nations_League.svg");
}

.special-flag.champions-league {
  background-image: url("../../media/flags/CL.svg");
}

.special-flag.afc-champions-league {
  background-image: url("../../media/flags/AFC-CHAMPIONS-LEAGUE.svg");
}

.special-flag.afc-cup {
  background-image: url("../../media/flags/AFC-CUP.svg");
}

.special-flag.concacaf-cl {
  background-image: url("../../media/flags/CONCACAF-CHAMPIONS-LEAGUE.svg");
}

.special-flag.copa-sudamericana {
  background-image: url("../../media/flags/COPA-SUDAMERICANA.svg");
}

.special-flag.ofc-cl {
  background-image: url("../../media/flags/OFC-CHAMPIONS-LEAGUE.svg");
}

.special-flag.copa-libertadores {
  background-image: url("../../media/flags/COPA-LIBERTADORES.svg");
}

.special-flag.friendlies {
  background-image: url("../../media/flags/friendlies.svg");
  background-size: cover;
}
