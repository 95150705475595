.game-history {
  display: flex;
  flex-direction: column;
  width: 75%;
  padding-bottom: 1rem;
}

.game-history-item {
  display: flex;
  margin-bottom: 0.5rem;
}

.game-history .home-team-history {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  text-align: right;
  width: calc(50% - 2.5rem);
}

.game-history .game-minute {
  width: 5rem;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.game-history .away-team-history {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: calc(50% - 2.5rem);
  text-align: left;
}

.game-history .minute {
  background-color: #f9bf1c;
  color: #000;
  border-radius: 50%;
  display: flex;
  height: 1.75rem;
  width: 1.75rem;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
}

.game-history .minute.extra-time {
  border: #f9bf1c 1px solid;
  background-color: transparent;
  color: #fff;
}

.game-history .yellow-card {
  color: #f9bf1c;
}

.game-history .red-card,
.game-history .own-goal {
  color: red;
}

.game-history p {
  font-weight: 100;
  font-size: 0.9rem;
}

@media (max-width: 767px) {
  .game-history {
    margin-left: 2.75rem;
  } 
  .game-history .home-team-history {
    width: calc(50% - 1.25rem);
  }
  .game-history .away-team-history {
    width: calc(50% - 2.75rem);
  }
  .game-history .game-result {
    width: 4rem;
    padding: 0 0.5rem;
  }
  .full-game-description .game-history{
    margin-left: 0;
  }
}
