@import url(https://fonts.googleapis.com/css?family=Ubuntu:300,400,400i,500,700&display=swap);
* {
  box-sizing: border-box;
}

body {
  margin: 0;
  background-color: #2e3032;
  color: #fff;
}

.default-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.main-container {
  flex: 1 1;
  width: 100%;
  max-width: 1190px;
  margin: 0 auto;
  padding-top: 3rem;
}

body .swiper-container {
  padding-bottom: 4rem;
}

.d-none {
  display: none !important;
}

button::-moz-focus-inner {
  border: 0;
}

/******************** CUSTOM ACADEMIA *********************/

body .bm_style.ico21,
.bm_style .ico21 {
  display: inline-block;
  width: 89px;
  height: 21px;
  background: url("https://academia.r.worldssl.net/system/cms/themes/shared/img/layout/bookmakers/bm_21x89@2x.png")
    no-repeat;
  background-size: 89px auto;
  vertical-align: middle;
}

body .bookmaker_small_icon {
  display: inline-block;
  width: 24px;
  height: 16px;
  background: url("https://academia.r.worldssl.net/system/cms/themes/shared/img/layout/bookmakers/bm_16x24@2x.png")
    no-repeat;
  background-size: 24px auto;
  vertical-align: middle;
  margin-left: 10%;
}

body .betfair-background {
  background-color: #ffb80c;
}

body .bet365-background {
  background-color: #35795d;
}

body .betpt-background {
  background-color: #000;
}

body .betano-background {
  background-color: #ff2a00;
}

body .melbet-background {
  background-color: #363b3f;
}

body ._1xbet-background {
  background-color: #2a5681;
}

@media (min-width: 1199px) {
  .d-xl-none {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .main-container {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .d-xs-none {
    display: none !important;
  }
}

@font-face {
  font-family: "FRTV";
  src: url(/static/media/FRTV.9d758d70.eot);
  src: url(/static/media/FRTV.9d758d70.eot#iefix) format("embedded-opentype"),
    url(/static/media/FRTV.5c930e02.ttf) format("truetype"),
    url(/static/media/FRTV.3f8f6d48.woff) format("woff"),
    url(/static/media/FRTV.684f150b.svg#FRTV) format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "FRTV" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-logout-frtv:before {
  content: "\e912";
}
.icon-penalty-goal-frtv:before {
  content: "\e916";
}
.icon-fail-penalty-frtv:before {
  content: "\e915";
}
.icon-defended-penalty-frtv:before {
  content: "\e913";
}
.icon-two-yellow-card-frtv:before {
  content: "\e914";
}
.icon-card-frtv:before {
  content: "\e90d";
}
.icon-competition-frtv:before {
  content: "\e900";
}
.icon-user-frtv:before {
  content: "\e901";
}
.icon-settings-frtv:before {
  content: "\e902";
}
.icon-games-frtv:before {
  content: "\e903";
}
.icon-tv-frtv:before {
  content: "\e904";
}
.icon-search-frtv:before {
  content: "\e905";
}
.icon-up-arrow-frtv:before {
  content: "\e906";
}
.icon-down-arrow-frtv:before {
  content: "\e907";
}
.icon-left-arrow-frtv:before {
  content: "\e908";
}
.icon-right-arrow-frtv:before {
  content: "\e909";
}
.icon-plus-frtv:before {
  content: "\e90a";
}
.icon-menus-frtv:before {
  content: "\e90b";
}
.icon-ball-frtv:before {
  content: "\e90c";
}
.icon-star-frtv:before {
  content: "\e90e";
}
.icon-star-outline-frtv:before {
  content: "\e90f";
}
.icon-accept-frtv:before {
  content: "\e910";
}
.icon-decline-frtv:before {
  content: "\e911";
}

html {
  font-family: "Ubuntu", sans-serif;
  font-size: 14px;
}

p {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: normal;
  margin: 0;
}

a {
  line-height: 1.2em;
  margin: 0;
  text-decoration: none;
  color: #161615;
}

h6 {
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: normal;
  margin: 0;
}

h5 {
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: normal;
  margin: 0;
}

h4 {
  font-size: 1.75rem;
  line-height: 2.25rem;
  font-weight: normal;
  margin: 0;
}

h3 {
  font-size: 2rem;
  line-height: 2.5rem;
  font-weight: normal;
  margin: 0;
}

h2 {
  font-size: 2.25rem;
  line-height: 2.75rem;
  font-weight: normal;
  margin: 0;
}

h1 {
  font-size: 2.5rem;
  line-height: 3rem;
  font-weight: normal;
  margin: 0;
}

button {
  font-family: inherit;
  font-weight: inherit;
}

@media (max-width: 768px) {
  html {
    font-size: 12px;
  }
}

.menu {
  display: flex;
  justify-content: flex-end;
  flex: 1 1;
  padding-right: 1rem;
  margin: 0;
  transition: all 0.5s ease;
}

.menu .menu-list {
  flex: 1 1;
  display: flex;
  justify-content: flex-end;
  list-style: none;
  margin: 0;
  padding: 0;
}

.menu .menu-list .menu-list-item {
  display: flex;
  align-items: center;
  opacity: 0.2;
  color: white;
  padding: 0 1.5rem;
  font-weight: bold;
  text-transform: uppercase;
}

.menu .menu-list .menu-list-item:hover {
  cursor: pointer;
  opacity: 1;
}

.menu .menu-list .menu-list-item b {
  margin: 0;
}

.menu .menu-list .menu-list-item.active {
  border-bottom: 3px solid #f9bf1c;
  opacity: 1;
}

.menu .menu-list-icon {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 1rem;
  height: 1.5rem;
  margin-right: 0.5rem;
}

.menu .cup-icon {
  background-image: url(/static/media/cup_white.c3e3feac.svg);
}

.menu .tv-icon {
  background-image: url(/static/media/tv_white.83eabd27.svg);
}

.menu .stadium-icon {
  background-image: url(/static/media/stadium_white.00182797.svg);
}

.menu .account-icon {
  background-image: url(/static/media/account_white.694311b6.svg);
}

@media (max-width: 1199px) {
  .menu {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    background-color: #0a0b0c;
    padding: 6.5rem 1rem;
    z-index: 19;
  }
  .menu.menu-closed {
    left: -220px;
  }
  .menu .menu-list {
    flex-direction: column;
    justify-content: flex-start;
  }
  .menu .menu-list .menu-list-item {
    padding: 1rem;
    border-radius: 10px;
  }
  .menu .menu-list .menu-list-item.active {
    color: #141619;
    border: none;
    opacity: 1;
    background-color: #f9bf1c;
  }
  .menu .menu-list .menu-list-item.active .stadium-icon {
    background-image: url(/static/media/stadium_black.46642d7e.svg);
  }
  .menu .menu-list .menu-list-item.active .cup-icon {
    background-image: url(/static/media/cup_black.1888d7a3.svg);
  }
  .menu .menu-list .menu-list-item.active .tv-icon {
    background-image: url(/static/media/tv_black.0322cf4f.svg);
  }
  .menu .menu-list .menu-list-item.active .account-icon {
    background-image: url(/static/media/account_black.351e5040.svg);
  }
}

header {
  display: flex;
  height: 5.5rem;
  border-bottom: 0.5px solid #56575b;
}

header .header-wrapper {
  display: flex;
  width: 100%;
  max-width: 1190px;
  margin: 0 auto;
}

header .header-logo {
  flex: 1 1;
  background-image: url("/img/frtv_logo_white.svg");
  background-size: 16rem;
  background-repeat: no-repeat;
  background-position: left;
  cursor: pointer;
}

header .menu-account,
.menu-settings,
.menu-search {
  width: 2.5rem;
  margin-right: 1rem;
  padding: 0;
  opacity: 0.2;
  border: none;
  background-size: 27px;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
}

header .menu-account {
  background-image: url(/static/media/account_white.694311b6.svg);
}

header .menu-settings {
  background-image: url(/static/media/settings_white.74d08383.svg);
  margin-right: 0;
}

header .menu-search {
  display: none;
  background-image: url(/static/media/search.8a99b844.svg);
}

@media (max-width: 1199px) {
  header {
    border: none;
    background-color: #0a0b0c;
  }
  header .mobile-menu-icon {
    position: absolute;
    left: 0;
    top: 0;
    width: 4rem;
    height: 5.5rem;
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    background-color: transparent;
    background-image: url(/static/media/menu_white.0f004758.svg);
    background-size: 0.5rem;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 21;
  }
  header .rotate-menu-icon {
    background-image: url(/static/media/menu_yellow.a8a41a4f.svg);
    background-size: 2rem;
    transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
  }
  header .header-logo {
    margin-left: 2rem;
    background-size: 13rem;
    background-position: center;
    z-index: 20;
  }
  header .menu-settings {
    margin-right: 1rem;
  }
  header .menu-search {
    display: block;
  }
}

footer {
  margin: 0 auto;
  max-width: 1190px;
}
footer .semi-footer {
  height: auto;
  border-bottom: 0.5px solid #56575b;
  padding: 7rem 0 3.5rem;
}

footer .semi-footer p,
footer .semi-footer b {
  opacity: 0.2;
  font-size: 1rem;
}

footer .footer-links {
  padding: 0;
  height: 5rem;
  display: flex;
  align-items: center;
}

footer .footer-links ul {
  list-style: none;
  display: inline-flex;
  float: left;
  padding: 0;
  margin: 0;
}

footer .footer-links li p {
  color: white;
  opacity: 0.2;
  text-decoration: none;
  font-weight: 300;
  font-size: 1rem;
  padding-right: 2rem;
}

footer .footer-links li p:hover {
  color: #f9bf1c;
  font-weight: 300;
  opacity: 1;
  cursor: pointer;
}

footer .footer-copyright {
  width: 30%;
  float: right;
  text-align: right;
}

footer .footer-copyright span {
  color: white;
  opacity: 0.2;
}

footer .footer-links-list {
  width: 70%;
  float: left;
}

footer .footer-copyright b {
  color: #f9bf1c;
  margin-right: 0.5rem;
}

@media (max-width: 1199px) {
  footer {
    padding: 0 1rem;
  }
  footer .footer-links-list {
    width: 100%;
    float: none;
    height: auto;
    padding: 1rem 0 0;
    display: flex;
    flex-flow: column-reverse;
    align-items: center;
  }
  footer .footer-copyright {
    width: 100%;
    float: none;
    text-align: center;
    padding: 1rem 0;
  }
  footer .footer-links {
    padding: 0;
    height: auto;
    display: block;
    align-items: center;
  }
  footer .footer-links ul {
    list-style: none;
    display: block;
    text-align: center;
  }
  footer .footer-links ul li {
    padding: 0 0 1rem 0;
  }
  footer .footer-links li a {
    font-size: 1rem;
    padding: 0;
  }
}

@media (max-width: 768px) {
  footer .semi-footer {
    padding-bottom: 1rem;
  }
  footer .semi-footer p,
  footer .semi-footer b {
    margin: 0;
  }
}

.flag-icon {
  border-radius: 5px;
  font-size: 1.5rem;
}

.special-flag {
  display: flex;
  height: 25px;
  width: 25px;
  font-size: 1.5rem;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.default-flag {
  background-image: url(/static/media/default.81e6e677.svg);
}

.special-flag.uefa {
  background-image: url(/static/media/uefa.f7b5bcb0.svg);
}

.special-flag.europa-league {
  background-image: url(/static/media/EL.399e9909.svg);
}

.special-flag.nations-league {
  background-image: url(/static/media/UEFA_Nations_League.c1473ef6.svg);
}

.special-flag.champions-league {
  background-image: url(/static/media/CL.c4334771.svg);
}

.special-flag.afc-champions-league {
  background-image: url(/static/media/AFC-CHAMPIONS-LEAGUE.04d567b8.svg);
}

.special-flag.afc-cup {
  background-image: url(/static/media/AFC-CUP.a6de06ec.svg);
}

.special-flag.concacaf-cl {
  background-image: url(/static/media/CONCACAF-CHAMPIONS-LEAGUE.acfda687.svg);
}

.special-flag.copa-sudamericana {
  background-image: url(/static/media/COPA-SUDAMERICANA.824fe6a0.svg);
}

.special-flag.ofc-cl {
  background-image: url(/static/media/OFC-CHAMPIONS-LEAGUE.5c2b0a4b.svg);
}

.special-flag.copa-libertadores {
  background-image: url(/static/media/COPA-LIBERTADORES.ce8cb681.svg);
}

.special-flag.friendlies {
  background-image: url(/static/media/friendlies.f5e9b5ea.svg);
  background-size: cover;
}

.live .game-time {
  color: #f9bf1c;
  opacity: 1;
}

.game-time {
  padding: 0 1rem;
  width: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 0.9rem;
}

.game-time p {
  font-size: 0.8rem;
  text-align: center;
  line-height: 1rem;
  opacity: 0.5;
}

.game {
  background-color: transparent;
  border-radius: 15px;
  background: #fff;
}

.game .top-game-competition {
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;
  background-color: #151516;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  cursor: pointer;
  justify-content: flex-start;
}

.game .game-description {
  display: flex;
  align-items: center;
  padding: 1rem;
  text-align: center;
  background: #fff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  cursor: pointer;
}

.game .top-game-competition .competition-icon {
  margin-right: 0.5rem;
}

.game .game-description .game-home-team,
.game .game-description .game-away-team {
  width: 30%;
  padding: 0 1rem;
}

.game .game-description .game-home-team p,
.game .game-description .game-away-team p {
  font-weight: 600;
  color: #151516;
  font-size: 1rem;
  line-height: 1.25rem;
}

.game .game-description .game-team-icon {
  height: 7rem;
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}

.game .game-description .game-details {
  width: 40%;
  height: auto;
  color: #151516;
  flex-direction: column;
  align-items: center;
}

.game .game-description .game-details.live .live-status {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.game .game-description .game-details.live .live-status > p {
  font-weight: 500;
  background-color: red;
  color: #fff;
  padding: 0 0.5rem;
  border-radius: 10px;
  margin-bottom: 0.5rem;
}

.game .separate {
  border-top: 0.5px solid #151516;
  opacity: 0.2;
  padding: 0.25rem 0;
}

.game .game-livestream,
.game .game-television {
  background-color: white;
  padding: 0 1rem;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.game .game-television {
  padding: 0 1rem 0.75rem;
}

.game .game-livestream p,
.game .game-television p {
  color: #151516;
  margin-bottom: 0.5rem;
  width: 100%;
  position: relative;
}

.game .game-television .television-list,
.game .game-livestream .livestream-list {
  display: inline-block;
}

.game .livestream-list .livestream,
.game .television-list .television {
  display: inline-flex;
}

.game .game-livestream,
.game .game-television {
  text-align: center;
}

.game .game-livestream.empty .livestream-list,
.game .game-television.empty .television-list {
  padding: 0.5rem 1rem;
  text-align: center;
  display: block;
}

.game .game-livestream.empty .livestream-list p,
.game .game-television.empty .television-list p {
  text-align: center;
  font-style: italic;
  opacity: 0.5;
}

.game .game-livestream .livestream-list .livestream {
  margin-bottom: 0.5rem;
}
.game .game-livestream .livestream-list .livestream:nth-child(2n) {
  margin-right: 0;
}

.game .game-television .television-list .television span {
  color: #151516;
}

.livestream {
  display: flex;
  height: 2.75rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  background-color: #151516;
  border-radius: 10px;
  width: calc(50% - 0.5rem);
}

.livestream:nth-child(2n) {
  margin-right: 0;
}

.livestream .livestream-icon {
  flex: 1 1;
  align-self: center;
}

.livestream .livestream-play {
  background-color: #f9bf1c;
  margin-right: -1px;
  width: 2.75rem;
  border-radius: 10px;
  background-image: url(/static/media/play.00c3cfbc.svg);
  background-size: 30%;
  background-repeat: no-repeat;
  background-position: center center;
}

.livestream_nextgames {
  display: flex;
}

.table-competition-game-row .television,
.television-list .television,
.television {
  align-items: center;
  background-color: transparent;
  margin-bottom: 0.25rem;
  margin-right: 1rem;
  cursor: pointer;
  max-width: 6rem;
}

.television-list .television:last-child {
  margin-right: 0;
}

.television span {
  color: #151516;
}

.television .television-icon {
  min-width: 1.25rem;
  height: 1.5rem;
  margin-right: 0.5rem;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(/static/media/tv_yellow.3d01c83f.svg);
}

.top-games {
  margin-bottom: 3rem;
}

.top-games .swiper-pagination-clickable .swiper-pagination-bullet {
  width: 1rem;
  height: 1rem;
  background-color: white;
  opacity: 0.2;
}

.top-games .swiper-pagination-clickable .swiper-pagination-bullet-active {
  background-color: #f9bf1c;
  opacity: 1;
}

@media (max-width: 768px) {
  .top-games {
    padding: 0;
  }
}

.spinner-loader {
  margin: 4rem auto;
  width: 10rem;
  height: 10rem;
  position: relative;
}

.spinner-loader.smaller {
  width: 5rem;
  height: 5rem;
  margin: 2rem auto;
}

.spinner-loader .sk-circle {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.spinner-loader .sk-circle:before {
  content: "";
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #f9bf1c;
  border-radius: 100%;
  -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
  animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
}

.spinner-loader .sk-circle2 {
  transform: rotate(30deg);
}

.spinner-loader .sk-circle3 {
  transform: rotate(60deg);
}

.spinner-loader .sk-circle4 {
  transform: rotate(90deg);
}

.spinner-loader .sk-circle5 {
  transform: rotate(120deg);
}

.spinner-loader .sk-circle6 {
  transform: rotate(150deg);
}

.spinner-loader .sk-circle7 {
  transform: rotate(180deg);
}

.spinner-loader .sk-circle8 {
  transform: rotate(210deg);
}

.spinner-loader .sk-circle9 {
  transform: rotate(240deg);
}

.spinner-loader .sk-circle10 {
  transform: rotate(270deg);
}

.spinner-loader .sk-circle11 {
  transform: rotate(300deg);
}

.spinner-loader .sk-circle12 {
  transform: rotate(330deg);
}

.spinner-loader .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.spinner-loader .sk-circle3:before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.spinner-loader .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.spinner-loader .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

.spinner-loader .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}

.spinner-loader .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}

.spinner-loader .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}

.spinner-loader .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}

.spinner-loader .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}

.spinner-loader .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}

.spinner-loader .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleFadeDelay {
  0%,
  39%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}

@keyframes sk-circleFadeDelay {
  0%,
  39%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}

.game-history {
  display: flex;
  flex-direction: column;
  width: 75%;
  padding-bottom: 1rem;
}

.game-history-item {
  display: flex;
  margin-bottom: 0.5rem;
}

.game-history .home-team-history {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  text-align: right;
  width: calc(50% - 2.5rem);
}

.game-history .game-minute {
  width: 5rem;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.game-history .away-team-history {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: calc(50% - 2.5rem);
  text-align: left;
}

.game-history .minute {
  background-color: #f9bf1c;
  color: #000;
  border-radius: 50%;
  display: flex;
  height: 1.75rem;
  width: 1.75rem;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
}

.game-history .minute.extra-time {
  border: #f9bf1c 1px solid;
  background-color: transparent;
  color: #fff;
}

.game-history .yellow-card {
  color: #f9bf1c;
}

.game-history .red-card,
.game-history .own-goal {
  color: red;
}

.game-history p {
  font-weight: 100;
  font-size: 0.9rem;
}

@media (max-width: 767px) {
  .game-history {
    margin-left: 2.75rem;
  } 
  .game-history .home-team-history {
    width: calc(50% - 1.25rem);
  }
  .game-history .away-team-history {
    width: calc(50% - 2.75rem);
  }
  .game-history .game-result {
    width: 4rem;
    padding: 0 0.5rem;
  }
  .full-game-description .game-history{
    margin-left: 0;
  }
}

.more-game-info {
  display: flex;
}

.more-game-info .right-cell {
  margin-right: 4rem;
  flex-direction: column;
}

.more-game-info .right-cell .livestream-container,
.more-game-info .right-cell .television-container {
  border-top: 1px solid #ffffff40;
  padding: 1rem 0 0;
}

.more-game-info .right-cell .livestream-container p,
.more-game-info .right-cell .television-container p {
  margin-bottom: 0.5rem;
}

.more-game-info .right-cell .livestream-container .livestream-list,
.more-game-info .right-cell .television-container .television-list {
  display: flex;
  flex-wrap: wrap;
}

.more-game-info .right-cell .livestream-container .livestream,
.more-game-info .right-cell .television-container .television {
  margin-bottom: 1rem;
}

.more-game-info .right-cell .more-sugestion {
  border: 1px solid #f9bf1c;
  background-color: transparent;
  color: #f9bf1c;
  padding: 0.5rem 1rem;
  margin-bottom: 0.5rem;
  border-radius: 15px;
  outline: none;
  cursor: pointer;
}

.more-game-info .right-cell .more-sugestion:hover {
  background-color: #f9bf1c;
  color: #151519;
}

@media (max-width: 767px) {
  .more-game-info {
    flex-direction: column;
  }
  .more-game-info .right-cell {
    width: 100%;
    margin: 0 0 1rem 0;
  }
  .more-game-info .right-cell .livestream-container,
  .more-game-info .right-cell .television-container {
    padding: 1rem 1rem 0;
  }
  .more-game-info .right-cell .livestream-container .livestream {
    margin-bottom: 1rem;
  }
  .more-game-info .right-cell .more-sugestion {
    margin: 0 1rem;
  }
}

@media (max-width: 768px) {
  .more-game-info .right-cell .livestream-container {
    padding-bottom: 0;
  }
  .more-game-info .livestream-list {
    flex-wrap: wrap;
  }
  .more-game-info
    .right-cell
    .livestream-container
    .livestream-list
    .livestream {
    /* width: calc(50% - 0.5rem); */
    flex: unset;
    margin-right: 1rem;
    margin-bottom: 1rem;
  }
  .more-game-info
    .right-cell
    .livestream-container
    .livestream-list
    .livestream:nth-child(2n) {
    margin-right: 0;
  }
}

.game-row-spacer {
  flex: 1 1;
}

.livestream-list {
  display: flex;
  align-items: center;
}

.livestream-list .livestream {
  width: 9.5rem;
  margin: 0 1rem 0 0;
}

.television {
  margin-bottom: 0;
}

.television span {
  color: #fff;
}

.table-competition-game-item:nth-child(2n-1) {
  background: #4b4f50;
  border-radius: 10px;
}

.table-competition-game-item.open {
  padding-bottom: 0.5rem;
}

.table-competition-game-item .table-competition-game-row {
  display: flex;
  border-radius: 10px;
  margin-top: 0.5rem;
  padding: 1rem 0;
}

.table-competition-game-item .left-cell {
  display: flex;
  width: 60%;
}

.table-competition-game-item .right-cell {
  display: flex;
  width: 45%;
}

.table-competition-game-item .table-competition-game-row .left-cell {
  cursor: pointer;
  justify-content: flex-start;
}

.table-competition-game-item .game-date {
  display: flex;
  align-items: center;
  width: 5.75rem;
  padding-left: 1rem;
  font-size: 0.9rem;
  flex-shrink: 0;
}

.table-competition-game-item .left-cell .game-round {
  width: 6.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}


.table-competition-game-item .game-details {
  width: 75%;
  /* margin-right: 5rem; */
}

.table-competition-game-item .right-cell {
  flex-shrink: 1;
}

.table-competition-game-item .plus-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.5rem;
}

.hidden {
  display: none !important;
}

.table-competition-game-item .plus-icon button {
  width: 1.5rem;
  height: 1.5rem;
  border: none;
  padding: 0;
  opacity: 0.2;
  outline: none;
  cursor: pointer;
  background-size: 1rem;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: transparent;
  background-image: url(/static/media/white-plus.2ab1bd4b.svg);
}

.table-competition-game-item .plus-icon.open button {
  background-image: url(/static/media/yellow-menus.97ed8181.svg);
  opacity: 1;
}

@media (max-width: 1199px) {
  .table-competition-game-item .left-cell,
  .table-competition-game-item .right-cell {
    width: calc(100% - 3.5rem);
  }
  .table-competition-game-item .more-game-info .left-cell,
  .table-competition-game-item .more-game-info .right-cell {
    width: 100%;
  }
  .table-competition-game-item .plus-icon {
    padding: 0 1rem;
  }
}

@media (max-width: 767px) {
  .table-competition-game-item .left-cell .game-round {
    display: none;
  }
}

@media (max-width: 320px) {
  .table-competition-game-item .game-details {
    width: 50%;
  }
}
.game-details {
  width: calc(100% - 5rem);
  display: flex;
}

.game-details .home-team {
  width: calc(50% - 2.5rem);
  padding-left: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: right;
}

.game-details .home-team p,
.game-details .away-team p {
  overflow: hidden;
  text-overflow: ellipsis;
}

.game-details .game-result {
  width: 5rem;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.live .game-details .game-result {
  color: #f9bf1c;
  opacity: 1;
}

.game-details .game-result p:not(:first-child) {
  font-size: 0.6rem;
  line-height: 0.8rem;
}

.game-details .away-team {
  width: calc(50% - 2.5rem);
  padding-right: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
}

@media (max-width: 1199px) {
  .game-details .home-team,
  .game-details .away-team {
    width: 50%;
  }

  .game-details .game-result {
    width: 4rem;
    padding: 0 0.5rem;
  }
}

.live .game-status {
  color: #f9bf1c;
  opacity: 1;
}

.game-status {
  height: 2rem;
  padding: 0.25rem 0.5rem;
  margin-left: 1rem;
  font-size: 0.8rem;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  border: 1px solid #f9bf1c;
  color: #f9bf1c;
  border-radius: 15px;
  opacity: 0;
}

.table-competition {
  margin-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #ffffff40;
}
.table-competition:last-child {
  border: none;
}

.table-competition .table-competition-top {
  display: flex;
  height: 3.25rem;
}

.table-competition .table-competition-top .competition-description {
  flex: 1 1;
  display: flex;
  font-size: 1.25rem;
  font-weight: 100;
}

.table-competition
  .table-competition-top
  .competition-description
  .competition-icon,
.table-competition
  .table-competition-top
  .competition-description
  .competition-name {
  margin-right: 0.5rem;
  align-self: center;
  cursor: pointer;
}

.table-competition
  .table-competition-top
  .competition-description
  .favorite-icon {
  width: 1.5rem;
  height: 1.5rem;
  border: none;
  align-self: center;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: transparent;
  background-image: url(/static/media/star-outline.4467f655.svg);
  outline: none;
  cursor: pointer;
}

.table-competition .table-competition-top .favorite-icon.active {
  background-image: url(/static/media/star.c7ac65e1.svg);
}

.table-competition
  .table-competition-top
  .competition-description
  .competition-spacer {
  flex: 1 1;
  cursor: pointer;
}

.table-competition .table-competition-top .expand-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 1rem;
  padding-right: 1.25rem;
  border-left: 1px solid #ffffff40;
  cursor: pointer;
}

.table-competition .table-competition-top .expand-icon button {
  width: 1.5rem;
  height: 1.5rem;
  background-size: 1rem;
  outline: none;
  border: none;
  opacity: 0.2;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: transparent;
  background-image: url(/static/media/arrow-up-white.704c07a2.svg);
}

.table-competition .table-competition-top .expand-icon.open button {
  background-image: url(/static/media/arrow-up-yellow.b247a25b.svg);
  opacity: 1;
}

.table-competition-games {
  padding-bottom: 0.5rem;
}

@media(max-width: 767px){
  .table-competition .table-competition-top .competition-description {
    font-size: 1.1rem;
  }

  .table-competition .table-competition-top .competition-description .competition-name{
    max-width: 70%;
  }
  .table-competition .table-competition-top .competition-description .favorite-icon{
    position: absolute;
    right: 5.5rem;
  }

}
.date-selector {
  display: flex;
  background-color: #f9bf1c;
  color: #151516;
  height: 3rem;
  border: none;
  float: right;
  font-size: 1rem;
  border-radius: 10px;
  position: absolute;
  right: 1rem;
  top: 0.65rem;
}

.date-selector .arrow-icon {
  width: 1rem;
  padding: 0 1rem;
  background-size: 30%;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.date-selector .arrow-icon.date-selector-left {
  background-image: url(/static/media/left-arrow.a0690209.svg);
}

.date-selector .arrow-icon.date-selector-right {
  background-image: url(/static/media/right-arrow.7f3e479d.svg);
}

.date-selector .calendar-date {
  background: transparent;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  font-size: 1rem;
  padding: 0 1rem;
  cursor: pointer;
}

.date-selector .calendar-date .calendar-icon {
  background: url(/static/media/calendar.be67d281.svg);
  width: 1rem;
  height: 1rem;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: transparent;
  border: none;
  outline: none;
  margin-right: 0.5rem;
}

.date-selector .date-picker {
  position: absolute;
  bottom: -10px;
  right: 0;
  transform: translate(0, 100%);
  background-color: #f9bf1c;
  z-index: 50;
  border-radius: 10px;
}

.date-selector .date-picker-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
}

@media (max-width: 768px) {
  .date-selector .calendar-date {
    padding: 0;
  }
}

.table-games h1 {
  text-align: center;
  margin-bottom: 3.5rem;
}

.table-games .table {
  background: #3f4344;
  box-shadow: 0px 0px 30px -9px rgba(0, 0, 0, 0.55);
  border-radius: 10px;
  min-height: 25rem;
}

.table-games .table .tab-list {
  display: flex;
  border-bottom: 1px solid #ffffff40;
  height: 4.5rem;
}

.table-games .table .tab-list .tab-selection {
  border: none;
  background: transparent;
  color: #fff;
  border-bottom: 2px solid transparent;
  padding: 1rem;
  opacity: 0.2;
  display: flex;
  align-items: center;
  outline: none;
  cursor: pointer;
}

.table-games .table .tab-list .tab-selection .live-counter {
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  background: #f9bf1c;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.5rem;
}

.table-games .table .tab-list .tab-selection b {
  font-size: 0.8rem;
  color: #3f4344;
}

.table-games .table .tab-list [data-selected] {
  border-bottom: 2px solid #f9bf1c;
  opacity: 1;
}

.table [data-reach-tab-panel] {
  outline: none;
  padding-bottom: 1rem;
}

.table [data-reach-tabs] {
  position: relative;
  padding: 0 1rem;
}

.table-games .table .empty-games {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5rem 1rem;
}

.table-games .table .empty-games .empty-image {
  background-image: url(/static/media/no_live_games.c729e1df.svg);
  width: 10rem;
  height: 10rem;
  background-size: cover;
  margin-bottom: 1rem;
}

.table-games .table .empty-games p {
  font-size: 1.5rem;
  line-height: 2rem;
}

.table-games .table .empty-games span {
  color: #f9bf1c;
}

.table-games h1 span {
  color: #f9bf1c;
}

.page-games h2,
.page-games h1 {
  text-align: center;
  margin-bottom: 1rem;
}

.page-games h2 span,
.page-games h1 span {
  color: #f9bf1c;
}

.page-games .sub-title {
  text-align: center;
  margin-bottom: 2rem;
  font-size: 1.25rem;
}

.page-games .home-page-texts {
  margin-top: 2rem;
}

.page-games .endTexts p{
  padding-top: 1rem;
}

.breadcrumbs {
  opacity: 0.25;
  color: #fff;
}

.breadcrumb-text {
  font-weight: 100;
}

.breadcrumb-text:hover {
  cursor: pointer;
  text-decoration: underline;
}

.breadcrumbs .breadcrumb-text,
.breadcrumbs span {
  margin-right: 0.5rem;
  text-transform: capitalize;
}

.competiton-list .competition {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: 100;
  cursor: pointer;
  height: 3rem;
}

.competition .icon-cup {
  width: 1.5rem;
  height: 1.5rem;
  align-self: center;
  border: none;
  margin: 0 0.5rem;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: transparent;
  background-image: url(/static/media/cup_yellow.ad3a3d0b.svg);
  outline: none;
  cursor: pointer;
}
.competition .competition-name {
  align-self: center;
  cursor: pointer;
}

.competition .favorite-icon {
  width: 1.5rem;
  height: 1.5rem;
  align-self: center;
  margin-left: 0.5rem;
  border: none;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: transparent;
  background-image: url(/static/media/star-outline.4467f655.svg);
  outline: none;
  cursor: pointer;
}

.competition .favorite-icon.active {
  background-image: url(/static/media/star.c7ac65e1.svg);
}

/* .competition .competition-spacer {
  flex: 1;
  cursor: pointer;
} */

.region {
  margin-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #ffffff40;
}

.region:last-child {
  border: none;
}

.region .region-top {
  height: 3.25rem;
  display: flex;
  cursor: pointer;
}

.region .region-description {
  flex: 1 1;
  display: flex;
  font-size: 1.25rem;
  font-weight: 100;
  padding-right: 1rem;
}

.region .region-description .region-icon,
.region .region-description .region-name {
  margin-right: 0.5rem;
  align-self: center;
}

.region .region-top small {
  font-size: 0.9rem;
  font-weight: bold;
  color: #f9bf1c;
  align-self: center;
}

.region .region-top .region-spacer {
  flex: 1 1;
  cursor: pointer;
}

.region .region-top .expand-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 1rem;
  padding-right: 1.25rem;
  border-left: 1px solid #ffffff40;
  cursor: pointer;
}

.region .region-top .expand-icon.open button {
  background-image: url(/static/media/arrow-up-yellow.b247a25b.svg);
  opacity: 1;
}

.region .region-top .expand-icon button {
  width: 1.5rem;
  height: 1.5rem;
  background-size: 1rem;
  outline: none;
  border: none;
  opacity: 0.2;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: transparent;
  background-image: url(/static/media/arrow-up-white.704c07a2.svg);
}

.region .special-flag {
  background-repeat: no-repeat;
  background-position: center center;
  background-color: transparent;
  width: 20px;
  height: 20px;
  float: left;
  background-size: 20px;
  padding: 0;
  background-image: url(/static/media/world.942066e8.svg);
}

.region .competiton-list {
  display: none;
}

.region.open .competiton-list {
  display: block;
}

.region .competiton-list .competition:nth-child(2n-1) {
  background: #4b4f50;
  border-radius: 10px;
  margin: 0.5rem 0;
  padding-right: 1rem;
}

.custom-filter {
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 2rem 0;
}

.custom-filter .filter-input {
  border: 1px solid white;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  opacity: 0.2;
  width: 50%;
}

.custom-filter .filter-input .btn-search {
  background: transparent;
  height: 1.5rem;
  width: 1.5rem;
  border: none;
  padding: 0;
  margin-right: 1rem;
  background-image: url(/static/media/search.8a99b844.svg);
  background-repeat: no-repeat;
  background-position: center center;
}

.custom-filter .filter-input button {
  cursor: pointer;
}

.custom-filter .filter-input button:focus {
  outline: none;
  background: red;
  cursor: pointer;
}

.custom-filter .filter-input input {
  height: 3.5rem;
  width: 100%;
  background: transparent;
  border: none;
  color: white;
  font-size: 1rem;
  outline: none;
  opacity: 1;
}

.custom-filter .filter-input input::-webkit-input-placeholder {
  color: white;
  font-size: 1rem;
  opacity: 1;
}

.custom-filter .filter-input input:-ms-input-placeholder {
  color: white;
  font-size: 1rem;
  opacity: 1;
}

.custom-filter .filter-input input::-ms-input-placeholder {
  color: white;
  font-size: 1rem;
  opacity: 1;
}

.custom-filter .filter-input input::placeholder {
  color: white;
  font-size: 1rem;
  opacity: 1;
}

@media (max-width: 768px) {
  .custom-filter {
    height: auto;
    margin: 1rem 0;
  }
  .custom-filter .filter-input {
    height: auto;
    width: 100%;
  }
}

.competition-table {
  min-height: 25rem;
  background: #3f4344;
  box-shadow: 0px 0px 30px -9px rgba(0, 0, 0, 0.55);
  border-radius: 10px;
  padding: 1rem;
  margin-bottom: 4rem;
}

.competition-table .region.close .competiton-list {
  display: none;
}

.competition-table .country:last-child {
  border: none;
}

.competition-table .empty-games {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5rem 1rem;
}

.competition-table .empty-games .empty-image {
  background-image: url(/static/media/no_live_games.c729e1df.svg);
  width: 10rem;
  height: 10rem;
  background-size: cover;
  margin-bottom: 1rem;
}

.competition-table .empty-games p {
  font-size: 1.5rem;
  line-height: 2rem;
}

.page-all-competitions .title {
  margin-bottom: 2rem;
}

.page-all-competitions h1 {
  text-align: left;
}

.page-all-competitions h1 span {
  text-transform: capitalize;
  color: #f9bf1c;
}

.page-all-competitions .endTexts p{
  padding-top: 1rem;
}

.television-list .television-channel,
.television-list .television-channel,
.television-channel {
  min-width: calc(25% - 0.75rem);
  max-width: calc(25% - 0.75rem);
  min-height: 5rem;
  padding: 1rem;
  background-color: #3f4344;
  box-shadow: 0px 0px 30px -9px rgba(0, 0, 0, 0.55);
  border-radius: 10px;
  display: flex;
  align-items: center;
  margin: 1rem 1rem 0 0;
  cursor: pointer;
}

.television-list .television-channel {
  justify-content: flex-start;
}

.television-channel:nth-child(4n) {
  margin-right: 0;
}

.television-channel .tv-icon {
  background-image: url(/static/media/tv_yellow.3d01c83f.svg);
  background-repeat: no-repeat;
  background-position: center center;
  min-width: 2rem;
  min-height: 2rem;
  margin-right: 1rem;
}

.television-channel p {
  font-size: 1rem;
}

@media (max-width: 768px) {
  .television-list .television-channel,
  .television-channel {
    width: 100%;
    max-width: unset;
    margin-right: 0;
  }
}

.livestream-channel {
  width: calc(25% - 0.75rem);
  height: 5rem;
  background-color: #3f4344;
  box-shadow: 0px 0px 30px -9px rgba(0, 0, 0, 0.55);
  border-radius: 10px;
  display: flex;
  align-items: center;
  margin: 1rem 1rem 0 0;
  cursor: pointer;
}

.livestream-channel:nth-child(4n) {
  margin-right: 0;
}

.liveStream-WithOut-Link {
  width: 100%;
  height: 100%;
  padding-top: 1.6rem;
}

@media (max-width: 768px) {
  .livestream-channel {
    width: 100%;
    margin-right: 0;
  }
}

.page-all-channels .live-stream-list-box {
  margin-bottom: 6rem;
}

.page-all-channels .live-stream-list-box h1,
.page-all-channels .live-stream-list-box h2 {
  margin-bottom: 1rem;
  color: #f9bf1c;
}

.page-all-channels .live-stream-list-box h1 span,
.page-all-channels .live-stream-list-box h2 span {
  color: #fff;
}

.page-all-channels .live-stream-list,
.page-all-channels .television-list {
  display: flex;
  flex-wrap: wrap;
}

.page-all-channels .endTexts p{
  padding-top: 1rem;
}
.page-all-channels .endTexts h3{
  padding-top: 1rem;
}

@media (max-width: 768px) {
  .page-all-channels .live-stream-list-box {
    margin-bottom: 4rem;
  }
}

.page-contacts h1 {
  color: #f9bf1c;
  margin-bottom: 1rem;
}

.page-contacts .main-container p {
  margin-bottom: 1rem;
}

.page-contacts .main-container p img {
  margin-bottom: -3px;
  width: 250px;
  height: 20px;
}

.page-terms-and-conditions h1 {
  color: #f9bf1c;
  margin-bottom: 1rem;
}

.page-terms-and-conditions h6 {
  color: #f9bf1c;
}

.page-terms-and-conditions .main-container p {
  margin-bottom: 1rem;
}

.page-terms-and-conditions .main-container p img {
  margin-bottom: -4px;
  width: 250px;
  height: 20px;
}

.page-privacy-policy .main-container h1 {
  color: #f9bf1c;
  margin-bottom: 1rem;
}
.page-privacy-policy .main-container h6 {
  color: #f9bf1c;
}

.page-privacy-policy .main-container p {
  margin-bottom: 1rem;
}
.page-privacy-policy .main-container p img {
  margin-bottom: -4px;
  width: 250px;
  height: 20px;
}

.competition-info {
  margin-bottom: 4rem;
}

.competition-info .table {
  background: #3f4344;
  box-shadow: 0px 0px 30px -9px rgba(0, 0, 0, 0.55);
  border-radius: 10px;
  min-height: 25rem;
}

.competition-info .table .tab-list {
  display: flex;
  border-bottom: 1px solid #ffffff40;
  height: 4.5rem;
}

.competition-info .table .tab-list .tab-selection {
  border: none;
  background: transparent;
  color: #fff;
  border-bottom: 2px solid transparent;
  padding: 1rem;
  opacity: 0.2;
  display: flex;
  align-items: center;
  outline: none;
  cursor: pointer;
}

.competition-info .table .tab-list [data-selected] {
  border-bottom: 2px solid #f9bf1c;
  opacity: 1;
}

.competition-info .table [data-reach-tab-panel] {
  outline: none;
  padding-bottom: 1rem;
}

.competition-info .table [data-reach-tabs] {
  position: relative;
  padding: 0 1rem;
}

.competition-info .table-list {
  padding-bottom: 0.5rem;
}

.competition-info .empty-games {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5rem 1rem;
}

.competition-info .empty-games .empty-image {
  background-image: url(/static/media/no_live_games.c729e1df.svg);
  width: 10rem;
  height: 10rem;
  background-size: cover;
  margin-bottom: 1rem;
}

.competition-info .empty-games p {
  font-size: 1.5rem;
  line-height: 2rem;
}

.page-competition .title {
  margin-bottom: 2rem;
}
.page-competition h1 {
  text-align: left;
}

.page-competition h1.bottom-h1 {
  margin-bottom: 2rem;
}

.page-competition h1 span {
  color: #f9bf1c;
}

.page-competition .favorite-icon {
  width: 2rem;
  height: 2rem;
  align-self: center;
  margin-left: 0.5rem;
  border: none;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: transparent;
  background-image: url(/static/media/star-outline.4467f655.svg);
  outline: none;
  cursor: pointer;
}

.page-competition .favorite-icon.active {
  background-image: url(/static/media/star.c7ac65e1.svg);
}

.page-competition .endTexts p{
  padding-top: 1rem;
}

.full-game-description {
  background-color: white;
  border-radius: 10px;
  margin-bottom: 2rem;
  padding: 3rem 1rem 0.4rem 1rem;
}
.full-game-description .top-cell {
  display: flex;
  align-items: center;
  text-align: center;
  background: #fff;
  border-radius: 10px;
}

.full-game-description .top-cell .game-home-team,
.full-game-description .top-cell .game-away-team {
  width: 30%;
}

.full-game-description .top-cell .game-home-team p,
.full-game-description .top-cell .game-away-team p {
  font-weight: 500;
  display: block;
  color: #151516;
  overflow: hidden;
  text-overflow: ellipsis;
}

.full-game-description .top-cell .game-team-icon {
  height: 7rem;
  width: 100%;
  margin: 0 0 0.25rem;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}

.full-game-description .top-cell .game-details {
  flex-direction: column;
  width: 40%;
  height: auto;
  color: #151516;
}
.full-game-description .top-cell .game-details .game-time {
  width: 100%;
}

.full-game-description .top-cell .game-details .full-game-status {
  font-size: 1.25rem;
  line-height: 1.25rem;
}

.full-game-description .top-cell .game-details strong {
  font-size: 2.5rem;
  line-height: 2.5rem;
}

.live-txt {
  padding: 0.25rem 0.5rem;
  margin-bottom: 0.5rem;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: red;
  border-radius: 15px;
}

.full-game-description.live .top-cell .game-details strong,
.full-game-description.live .top-cell .game-details .game-time {
  color: red;
}

.full-game-description .bottom-cell .game-history {
  width: 100%;
  color: #000;
  padding: 0;
  padding-top: 1rem;
}

.full-game-description.live .bottom-cell .game-history .minute {
  background-color: red;
  color: #fff;
}

.full-game-description.live .bottom-cell .game-history .minute.extra-time {
  border-color: red;
  background-color: transparent;
}

.full-game-description .bottom-cell .game-history .home-team-history,
.full-game-description .bottom-cell .game-history .away-team-history {
  width: calc(50% - 2.5rem);
}

.full-game-description .bottom-cell .game-history .minute.extra-time {
  color: #000;
}

.full-game-description .separate {
  border-top: 0.5px solid #151516;
  opacity: 0.2;
  padding: 0.25rem 0;
}

.full-game-description .game-livestream {
  background-color: white;
  padding: 0 1rem;
  margin-top: 1rem;
}

.full-game-description .game-television {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  background-color: white;
  padding: 0 1rem;
}
.full-game-description .game-livestream p,
.full-game-description .game-television p {
  color: #2e3032;
  margin-bottom: 0.5rem;
}

.full-game-description .game-livestream span,
.full-game-description .game-television span {
  color: #151516;
}

.full-game-description .game-television .television-list,
.full-game-description .game-livestream .livestream-list {
  display: inline-block;
}

.full-game-description .game-livestream.empty .livestream-list p,
.full-game-description .game-television.empty .television-list p {
  text-align: center;
  font-style: italic;
  opacity: 0.5;
}

.full-game-description .game-television .television-list {
  display: block;
  text-align: center;
}

.full-game-description .game-livestream,
.full-game-description .game-television {
  text-align: center;
}

.full-game-description .game-livestream.empty .livestream-list a,
.full-game-description .game-television.empty .television-list div {
  display: inline-flex;
}

.full-game-description .livestream-list .livestream,
.full-game-description .television-list .television {
  margin-bottom: 1rem;
  display: inline-flex;
}

.full-game-description .game-livestream.empty .livestream-list,
.full-game-description .game-television.empty .television-list {
  padding: 0.5rem 1rem;
  text-align: center;
  display: block;
}

@media (min-width: 1200px) {
  .full-game-description .livestream-list .livestream:nth-child(8n) {
    margin-right: 0;
  }
}

@media (max-width: 768px) {
  .full-game-description .livestream-list .livestream:nth-child(2n) {
    margin-right: 0;
  }
}

.h2h-match {
  display: flex;
  border-radius: 10px;
  margin-bottom: 0.5rem;
  padding: 1rem 0;
  cursor: pointer;
}

.h2h-match:nth-child(2n) {
  background: #dbdcdc;
  border-radius: 10px;
}

.h2h-match:last-child {
  margin-bottom: 0;
}

.h2h-match .game-date {
  display: flex;
  align-items: center;
  width: 5.75rem;
  padding-left: 1rem;
  font-size: 0.9rem;
}

.h2h-match .game-competition {
  width: 10rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.h2h-match .game-competition p {
  font-size: 0.9rem;
  line-height: 1.25rem;
  text-align: center;
}

.h2h-match .game-details {
  width: calc(100% - 15.75rem);
}

@media (max-width: 1199px) {
  .h2h-match {
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 0.5rem;
  }
  .h2h-match .game-details {
    padding: 0 0.5rem;
  }
}

@media (max-width: 500px) {
  .h2h-match .game-competition {
    width: calc(100% - 10.75rem);
    flex-direction: row;
    flex-wrap: wrap;
    padding-left: 1rem;
  }
  .h2h-match .game-competition p:first-child {
    margin-right: 0.5rem;
  }
  .h2h-match .game-details {
    width: 100%;
  }
}

.head-to-head {
  border-radius: 10px;
  margin-bottom: 2rem;
}
.head-to-head.empty-widget {
  height: 10rem;
  border: 4px dashed rgba(255, 255, 255, 0.15);
  color: rgba(255, 255, 255, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
}

.head-to-head.empty-widget p {
  font-size: 1.2rem;
  line-height: 1.75rem;
}

.head-to-head .sub-title {
  display: flex;
  align-items: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #151516;
  color: #fff;
  padding: 0.5rem 1rem;
}

.head-to-head .sub-title span {
  margin-right: 0.5rem;
  color: #ffb80c;
}

.head-to-head .container {
  padding: 1rem 1rem;
  color: #151516;
  background-color: #fff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.head-to-head .television{
  width: 100%;
  display: contents;
}

.next-games .next-available-game,
.next-available-game {
  display: flex;
  border-radius: 10px;
  padding: 1rem 0;
  cursor: pointer;
  margin-bottom: 0.5rem;
}

.next-available-game:nth-child(2n) {
  background: #dbdcdc;
  border-radius: 10px;
}

.next-available-game:last-child {
  margin-bottom: 0;
}
.next-available-game .top-cell {
  display: flex;
  width: 60%;
}

.next-available-game .game-date {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 1rem;
  flex-shrink: 0;
}

.next-available-game .game-date p {
  font-size: 0.9rem;
  line-height: 1.15rem;
}

.next-available-game .game-logos {
  width: 7.5rem;
  display: flex;
  align-items: center;
  margin: 0 1rem;
  flex-shrink: 0;
  flex-basis: 25%;
}

.next-available-game .game-logos .game-team-icon {
  height: 2.5rem;
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}

.next-available-game .match-teams {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 1;
  padding-right: 1rem;
}

.next-available-game .television-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex: 1 1;
}

.next-available-game .television span {
  color: #000;
}

.next-available-game .left {
  left: 0;
  width: 30%;
  padding: 10px 10px 0 0;
}

.next-available-game .left .dual_live{
  margin-top: 0.3rem;
}
.next-available-game .right {
  right: 0;
  width: 70%;
  padding: 10px 10px 0 0;
}

.next-available-game .home-team {
  padding-left: 2rem;
  flex-direction: column;
  flex-grow: 2;
  flex-shrink: 2;
  flex-basis: 30%;
  align-items: flex-end;
  max-width: 7rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.next-available-game .away-team {
  flex-direction: column;
  flex-grow: 2;
  flex-shrink: 2;
  flex-basis: 30%;
  align-items: flex-start;
  max-width: 7rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 1199px){

  .next-available-game .top-cell {
      justify-content: flex-start;
  }

  .next-available-game .home-team {
    padding-left: 8rem;
    flex-basis: 0%;
    max-width: 18rem;
  }

  .next-available-game .game-logos {
    flex-basis: 15%;
  }

  .next-available-game .away-team {
    flex-basis: 0%;
    max-width: 11rem;
  }
}

@media (max-width: 767px) {
  .next-games .next-available-game,
  .next-available-game {
    flex-direction: column;
    width: 100%;
  }


  .next-available-game .home-team {
    padding-left: 2rem;
    flex-basis: 0%;
    max-width: 8rem;
  }

  .next-available-game .game-logos {
    flex-basis: 25%;
  }

  .next-available-game .away-team {
    flex-basis: 0%;
    max-width: 8rem;
  }

  .next-available-game .game-details {
    width: 100%;
  }

  .next-available-game .top-cell {
    width: 100%;
  }
  .next-available-game .television-list {
    justify-content: center;
    margin-top: 5%;
    text-align: center;
  }
  .next-available-game .left .dual_live{
    display: inline-block;
    width: 40%; 
    margin-top: 0.2rem;
  }
  
}

@media (max-width: 320px) {
  .next-available-game .game-logos {
    margin: 0 0rem;
  }
}

.next-games {
  border-radius: 10px;
}

.next-games.empty-widget {
  height: 10rem;
  border: 4px dashed rgba(255, 255, 255, 0.15);
  color: rgba(255, 255, 255, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
}

.next-games.empty-widget p {
  font-size: 1.2rem;
  line-height: 1.75rem;
}

.next-games .sub-title {
  display: flex;
  align-items: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #151516;
  color: #fff;
  padding: 0.5rem 1rem;
}

.next-games .sub-title .television-icon {
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(/static/media/tv_yellow.3d01c83f.svg);
}

.next-games .sub-title span {
  margin-right: 0.5rem;
  color: #ffb80c;
}

.next-games .container {
  padding: 1rem 1rem;
  color: #151516;
  background-color: #fff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.page-single-game .title {
  margin-bottom: 2rem;
}
.page-single-game h1 {
  text-align: left;
  margin-bottom: 0.5rem;
}
.page-single-game h2 {
  text-align: left;
  margin-bottom: 2rem;
}

.page-single-game h1 span,
.page-single-game h2 span {
  color: #f9bf1c;
}

.page-single-game .competition-name {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 0.5rem;
}

.page-single-game p span {
  margin-right: 0.5rem;
}

.page-single-game .widgets {
  display: flex;
  margin-bottom: 4rem;
}

.page-single-game .widgets > .right-cell {
  width: calc(50% - 1rem);
}
.page-single-game .endTexts p{
  padding-top: 1rem;
}

@media (max-width: 1199px) {
  .page-single-game .widgets {
    flex-direction: column;
  }

  .page-single-game .widgets > .right-cell {
    width: 100%;
  }
}

.other-tv-row {
  display: flex;
  border-radius: 10px;
  margin-bottom: 0.5rem;
  padding: 0.5rem 0;
}

.other-tv-row:nth-child(2n) {
  background: #dbdcdc;
}

.other-tv-row:last-child {
  margin-bottom: 0;
}

.other-tv-row p {
  font-size: 0.9rem;
}

.other-tv-row .left-cell {
  display: flex;
  align-items: center;
  width: 40%;
  padding-left: 1rem;
}

.other-tv-row .left-cell span {
  margin-right: 0.5rem;
}

.other-tv-row .right-cell {
  width: 60%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.other-tv-row .right-cell span {
  font-size: 0.9rem;
  margin-right: 0.5rem;
}

.other-tv {
  width: calc(50% - 1rem);
  margin-right: 2rem;
  border-radius: 10px;
  display: flex;
  flex: 1 1;
  flex-direction: column;
}

.other-tv.empty-widget {
  min-height: 20rem;
  border: 4px dashed rgba(255, 255, 255, 0.15);
  color: rgba(255, 255, 255, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
}

.other-tv.empty-widget p {
  font-size: 1.2rem;
  line-height: 1.75rem;
}

.other-tv .sub-title {
  display: flex;
  align-items: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #151516;
  color: #fff;
  padding: 0.5rem 1rem;
}

.other-tv .sub-title .television-icon {
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(/static/media/tv_yellow.3d01c83f.svg);
}

.other-tv .sub-title span {
  margin-right: 0.5rem;
}

.other-tv .container {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  padding: 1rem 1rem;
  color: #151516;
  background-color: #fff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.other-tv .container .warning {
  padding: 0.75rem 1rem;
  margin-top: auto;
}
.other-tv .container .warning span {
  color: #f9bf1c;
}

@media (max-width: 1199px) {
  .other-tv {
    width: 100%;
    margin-right: 0;
    margin-bottom: 2rem;
  }
}

.page-single-channel .title {
  margin-bottom: 2rem;
}

.page-single-channel h1 {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.page-single-channel h1 span {
  color: #f9bf1c;
}
.page-single-channel h1 .tv-icon {
  display: block;
  background-image: url(/static/media/tv_yellow.3d01c83f.svg);
  background-repeat: no-repeat;
  background-position: center center;
  width: 3rem;
  height: 3rem;
  margin-right: 0.5rem;
}

.page-single-channel .table {
  background: #3f4344;
  box-shadow: 0px 0px 30px -9px rgba(0, 0, 0, 0.55);
  border-radius: 10px;
  min-height: 10rem;
  margin-bottom: 4rem;
}
.page-single-channel .table.empty-widget {
  background: transparent;
  box-shadow: none;
  border: 4px dashed rgba(255, 255, 255, 0.15);
  color: rgba(255, 255, 255, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-single-channel .table.empty-widget p {
  font-size: 1.2rem;
  line-height: 1.75rem;
}

.page-single-channel .table .tab-list {
  display: flex;
  border-bottom: 1px solid #ffffff40;
  height: 4.5rem;
}

.page-single-channel .table .tab-list .tab-selection {
  border: none;
  background: transparent;
  color: #fff;
  border-bottom: 2px solid transparent;
  padding: 1rem;
  opacity: 0.2;
  display: flex;
  align-items: center;
  outline: none;
  cursor: pointer;
}

.page-single-channel .table .tab-list .tab-selection .live-counter {
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  background: #f9bf1c;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.5rem;
}

.page-single-channel .table .tab-list .tab-selection b {
  font-size: 0.8rem;
  color: #3f4344;
}

.page-single-channel .table .tab-list [data-selected] {
  border-bottom: 2px solid #f9bf1c;
  opacity: 1;
}

.page-single-channel .table [data-reach-tab-panel] {
  outline: none;
  padding-bottom: 1rem;
}

.page-single-channel .table [data-reach-tabs] {
  position: relative;
  padding: 0 1rem;
}

.page-single-channel .bottom-cell {
  display: flex;
}

.page-single-channel .bottom-cell h1 {
  margin-bottom: 1rem;
}

.page-single-channel .live-stream-list-box {
  width: calc(40% - 1rem);
  margin-bottom: 4rem;
  margin-right: 2rem;
}

.page-single-channel .top-games-wrapper {
  width: calc(60% - 1rem);
  margin-bottom: 4rem;
}

.page-single-channel .television-list {
  display: flex;
  flex-wrap: wrap;
}

.page-single-channel .endTexts p{
  padding-top: 1rem;
}

.page-single-channel .television-list .television-channel {
  min-width: calc(50% - 0.5rem);
}

@media (min-width: 768px) {
  .page-single-channel .television-list .television-channel:nth-child(2n) {
    margin-right: 0;
  }

  .page-single-channel .television-list .television-channel:nth-child(4n) {
    min-width: calc(50% - 0.5rem);
  }
}

@media (max-width: 1199px) {
  .page-single-channel .bottom-cell {
    flex-direction: column;
  }

  .page-single-channel .live-stream-list-box,
  .page-single-channel .top-games-wrapper {
    width: 100%;
    margin-right: 0;
  }
}

.span-space {
  display: contents;
}

