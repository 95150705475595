.page-terms-and-conditions h1 {
  color: #f9bf1c;
  margin-bottom: 1rem;
}

.page-terms-and-conditions h6 {
  color: #f9bf1c;
}

.page-terms-and-conditions .main-container p {
  margin-bottom: 1rem;
}

.page-terms-and-conditions .main-container p img {
  margin-bottom: -4px;
  width: 250px;
  height: 20px;
}
