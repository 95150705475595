.table-competition-game-item:nth-child(2n-1) {
  background: #4b4f50;
  border-radius: 10px;
}

.table-competition-game-item.open {
  padding-bottom: 0.5rem;
}

.table-competition-game-item .table-competition-game-row {
  display: flex;
  border-radius: 10px;
  margin-top: 0.5rem;
  padding: 1rem 0;
}

.table-competition-game-item .left-cell {
  display: flex;
  width: 60%;
}

.table-competition-game-item .right-cell {
  display: flex;
  width: 45%;
}

.table-competition-game-item .table-competition-game-row .left-cell {
  cursor: pointer;
  justify-content: flex-start;
}

.table-competition-game-item .game-date {
  display: flex;
  align-items: center;
  width: 5.75rem;
  padding-left: 1rem;
  font-size: 0.9rem;
  flex-shrink: 0;
}

.table-competition-game-item .left-cell .game-round {
  width: 6.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}


.table-competition-game-item .game-details {
  width: 75%;
  /* margin-right: 5rem; */
}

.table-competition-game-item .right-cell {
  flex-shrink: 1;
}

.table-competition-game-item .plus-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.5rem;
}

.hidden {
  display: none !important;
}

.table-competition-game-item .plus-icon button {
  width: 1.5rem;
  height: 1.5rem;
  border: none;
  padding: 0;
  opacity: 0.2;
  outline: none;
  cursor: pointer;
  background-size: 1rem;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: transparent;
  background-image: url("../../../../media/icons/white-plus.svg");
}

.table-competition-game-item .plus-icon.open button {
  background-image: url("../../../../media/icons/yellow-menus.svg");
  opacity: 1;
}

@media (max-width: 1199px) {
  .table-competition-game-item .left-cell,
  .table-competition-game-item .right-cell {
    width: calc(100% - 3.5rem);
  }
  .table-competition-game-item .more-game-info .left-cell,
  .table-competition-game-item .more-game-info .right-cell {
    width: 100%;
  }
  .table-competition-game-item .plus-icon {
    padding: 0 1rem;
  }
}

@media (max-width: 767px) {
  .table-competition-game-item .left-cell .game-round {
    display: none;
  }
}

@media (max-width: 320px) {
  .table-competition-game-item .game-details {
    width: 50%;
  }
}