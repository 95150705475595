.game-details {
  width: calc(100% - 5rem);
  display: flex;
}

.game-details .home-team {
  width: calc(50% - 2.5rem);
  padding-left: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: right;
}

.game-details .home-team p,
.game-details .away-team p {
  overflow: hidden;
  text-overflow: ellipsis;
}

.game-details .game-result {
  width: 5rem;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.live .game-details .game-result {
  color: #f9bf1c;
  opacity: 1;
}

.game-details .game-result p:not(:first-child) {
  font-size: 0.6rem;
  line-height: 0.8rem;
}

.game-details .away-team {
  width: calc(50% - 2.5rem);
  padding-right: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
}

@media (max-width: 1199px) {
  .game-details .home-team,
  .game-details .away-team {
    width: 50%;
  }

  .game-details .game-result {
    width: 4rem;
    padding: 0 0.5rem;
  }
}
