.competition-info {
  margin-bottom: 4rem;
}

.competition-info .table {
  background: #3f4344;
  box-shadow: 0px 0px 30px -9px rgba(0, 0, 0, 0.55);
  border-radius: 10px;
  min-height: 25rem;
}

.competition-info .table .tab-list {
  display: flex;
  border-bottom: 1px solid #ffffff40;
  height: 4.5rem;
}

.competition-info .table .tab-list .tab-selection {
  border: none;
  background: transparent;
  color: #fff;
  border-bottom: 2px solid transparent;
  padding: 1rem;
  opacity: 0.2;
  display: flex;
  align-items: center;
  outline: none;
  cursor: pointer;
}

.competition-info .table .tab-list [data-selected] {
  border-bottom: 2px solid #f9bf1c;
  opacity: 1;
}

.competition-info .table [data-reach-tab-panel] {
  outline: none;
  padding-bottom: 1rem;
}

.competition-info .table [data-reach-tabs] {
  position: relative;
  padding: 0 1rem;
}

.competition-info .table-list {
  padding-bottom: 0.5rem;
}

.competition-info .empty-games {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5rem 1rem;
}

.competition-info .empty-games .empty-image {
  background-image: url("../../media/no_live_games.svg");
  width: 10rem;
  height: 10rem;
  background-size: cover;
  margin-bottom: 1rem;
}

.competition-info .empty-games p {
  font-size: 1.5rem;
  line-height: 2rem;
}
