@font-face {
  font-family: "FRTV";
  src: url("fonts/FRTV.eot?n1mfta");
  src: url("fonts/FRTV.eot?n1mfta#iefix") format("embedded-opentype"),
    url("fonts/FRTV.ttf?n1mfta") format("truetype"),
    url("fonts/FRTV.woff?n1mfta") format("woff"),
    url("fonts/FRTV.svg?n1mfta#FRTV") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "FRTV" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-logout-frtv:before {
  content: "\e912";
}
.icon-penalty-goal-frtv:before {
  content: "\e916";
}
.icon-fail-penalty-frtv:before {
  content: "\e915";
}
.icon-defended-penalty-frtv:before {
  content: "\e913";
}
.icon-two-yellow-card-frtv:before {
  content: "\e914";
}
.icon-card-frtv:before {
  content: "\e90d";
}
.icon-competition-frtv:before {
  content: "\e900";
}
.icon-user-frtv:before {
  content: "\e901";
}
.icon-settings-frtv:before {
  content: "\e902";
}
.icon-games-frtv:before {
  content: "\e903";
}
.icon-tv-frtv:before {
  content: "\e904";
}
.icon-search-frtv:before {
  content: "\e905";
}
.icon-up-arrow-frtv:before {
  content: "\e906";
}
.icon-down-arrow-frtv:before {
  content: "\e907";
}
.icon-left-arrow-frtv:before {
  content: "\e908";
}
.icon-right-arrow-frtv:before {
  content: "\e909";
}
.icon-plus-frtv:before {
  content: "\e90a";
}
.icon-menus-frtv:before {
  content: "\e90b";
}
.icon-ball-frtv:before {
  content: "\e90c";
}
.icon-star-frtv:before {
  content: "\e90e";
}
.icon-star-outline-frtv:before {
  content: "\e90f";
}
.icon-accept-frtv:before {
  content: "\e910";
}
.icon-decline-frtv:before {
  content: "\e911";
}
