header {
  display: flex;
  height: 5.5rem;
  border-bottom: 0.5px solid #56575b;
}

header .header-wrapper {
  display: flex;
  width: 100%;
  max-width: 1190px;
  margin: 0 auto;
}

header .header-logo {
  flex: 1;
  background-image: url("/img/frtv_logo_white.svg");
  background-size: 16rem;
  background-repeat: no-repeat;
  background-position: left;
  cursor: pointer;
}

header .menu-account,
.menu-settings,
.menu-search {
  width: 2.5rem;
  margin-right: 1rem;
  padding: 0;
  opacity: 0.2;
  border: none;
  background-size: 27px;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
}

header .menu-account {
  background-image: url("../../media/icons/account_white.svg");
}

header .menu-settings {
  background-image: url("../../media/icons/settings_white.svg");
  margin-right: 0;
}

header .menu-search {
  display: none;
  background-image: url("../../media/icons/search.svg");
}

@media (max-width: 1199px) {
  header {
    border: none;
    background-color: #0a0b0c;
  }
  header .mobile-menu-icon {
    position: absolute;
    left: 0;
    top: 0;
    width: 4rem;
    height: 5.5rem;
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    background-color: transparent;
    background-image: url("../../media/icons/menu_white.svg");
    background-size: 0.5rem;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 21;
  }
  header .rotate-menu-icon {
    background-image: url("../../media/icons/menu_yellow.svg");
    background-size: 2rem;
    transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
  }
  header .header-logo {
    margin-left: 2rem;
    background-size: 13rem;
    background-position: center;
    z-index: 20;
  }
  header .menu-settings {
    margin-right: 1rem;
  }
  header .menu-search {
    display: block;
  }
}
