.page-single-game .title {
  margin-bottom: 2rem;
}
.page-single-game h1 {
  text-align: left;
  margin-bottom: 0.5rem;
}
.page-single-game h2 {
  text-align: left;
  margin-bottom: 2rem;
}

.page-single-game h1 span,
.page-single-game h2 span {
  color: #f9bf1c;
}

.page-single-game .competition-name {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 0.5rem;
}

.page-single-game p span {
  margin-right: 0.5rem;
}

.page-single-game .widgets {
  display: flex;
  margin-bottom: 4rem;
}

.page-single-game .widgets > .right-cell {
  width: calc(50% - 1rem);
}
.page-single-game .endTexts p{
  padding-top: 1rem;
}

@media (max-width: 1199px) {
  .page-single-game .widgets {
    flex-direction: column;
  }

  .page-single-game .widgets > .right-cell {
    width: 100%;
  }
}
