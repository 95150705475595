.full-game-description {
  background-color: white;
  border-radius: 10px;
  margin-bottom: 2rem;
  padding: 3rem 1rem 0.4rem 1rem;
}
.full-game-description .top-cell {
  display: flex;
  align-items: center;
  text-align: center;
  background: #fff;
  border-radius: 10px;
}

.full-game-description .top-cell .game-home-team,
.full-game-description .top-cell .game-away-team {
  width: 30%;
}

.full-game-description .top-cell .game-home-team p,
.full-game-description .top-cell .game-away-team p {
  font-weight: 500;
  display: block;
  color: #151516;
  overflow: hidden;
  text-overflow: ellipsis;
}

.full-game-description .top-cell .game-team-icon {
  height: 7rem;
  width: 100%;
  margin: 0 0 0.25rem;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}

.full-game-description .top-cell .game-details {
  flex-direction: column;
  width: 40%;
  height: auto;
  color: #151516;
}
.full-game-description .top-cell .game-details .game-time {
  width: 100%;
}

.full-game-description .top-cell .game-details .full-game-status {
  font-size: 1.25rem;
  line-height: 1.25rem;
}

.full-game-description .top-cell .game-details strong {
  font-size: 2.5rem;
  line-height: 2.5rem;
}

.live-txt {
  padding: 0.25rem 0.5rem;
  margin-bottom: 0.5rem;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: red;
  border-radius: 15px;
}

.full-game-description.live .top-cell .game-details strong,
.full-game-description.live .top-cell .game-details .game-time {
  color: red;
}

.full-game-description .bottom-cell .game-history {
  width: 100%;
  color: #000;
  padding: 0;
  padding-top: 1rem;
}

.full-game-description.live .bottom-cell .game-history .minute {
  background-color: red;
  color: #fff;
}

.full-game-description.live .bottom-cell .game-history .minute.extra-time {
  border-color: red;
  background-color: transparent;
}

.full-game-description .bottom-cell .game-history .home-team-history,
.full-game-description .bottom-cell .game-history .away-team-history {
  width: calc(50% - 2.5rem);
}

.full-game-description .bottom-cell .game-history .minute.extra-time {
  color: #000;
}

.full-game-description .separate {
  border-top: 0.5px solid #151516;
  opacity: 0.2;
  padding: 0.25rem 0;
}

.full-game-description .game-livestream {
  background-color: white;
  padding: 0 1rem;
  margin-top: 1rem;
}

.full-game-description .game-television {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  background-color: white;
  padding: 0 1rem;
}
.full-game-description .game-livestream p,
.full-game-description .game-television p {
  color: #2e3032;
  margin-bottom: 0.5rem;
}

.full-game-description .game-livestream span,
.full-game-description .game-television span {
  color: #151516;
}

.full-game-description .game-television .television-list,
.full-game-description .game-livestream .livestream-list {
  display: inline-block;
}

.full-game-description .game-livestream.empty .livestream-list p,
.full-game-description .game-television.empty .television-list p {
  text-align: center;
  font-style: italic;
  opacity: 0.5;
}

.full-game-description .game-television .television-list {
  display: block;
  text-align: center;
}

.full-game-description .game-livestream,
.full-game-description .game-television {
  text-align: center;
}

.full-game-description .game-livestream.empty .livestream-list a,
.full-game-description .game-television.empty .television-list div {
  display: inline-flex;
}

.full-game-description .livestream-list .livestream,
.full-game-description .television-list .television {
  margin-bottom: 1rem;
  display: inline-flex;
}

.full-game-description .game-livestream.empty .livestream-list,
.full-game-description .game-television.empty .television-list {
  padding: 0.5rem 1rem;
  text-align: center;
  display: block;
}

@media (min-width: 1200px) {
  .full-game-description .livestream-list .livestream:nth-child(8n) {
    margin-right: 0;
  }
}

@media (max-width: 768px) {
  .full-game-description .livestream-list .livestream:nth-child(2n) {
    margin-right: 0;
  }
}
