.table-games h1 {
  text-align: center;
  margin-bottom: 3.5rem;
}

.table-games .table {
  background: #3f4344;
  box-shadow: 0px 0px 30px -9px rgba(0, 0, 0, 0.55);
  border-radius: 10px;
  min-height: 25rem;
}

.table-games .table .tab-list {
  display: flex;
  border-bottom: 1px solid #ffffff40;
  height: 4.5rem;
}

.table-games .table .tab-list .tab-selection {
  border: none;
  background: transparent;
  color: #fff;
  border-bottom: 2px solid transparent;
  padding: 1rem;
  opacity: 0.2;
  display: flex;
  align-items: center;
  outline: none;
  cursor: pointer;
}

.table-games .table .tab-list .tab-selection .live-counter {
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  background: #f9bf1c;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.5rem;
}

.table-games .table .tab-list .tab-selection b {
  font-size: 0.8rem;
  color: #3f4344;
}

.table-games .table .tab-list [data-selected] {
  border-bottom: 2px solid #f9bf1c;
  opacity: 1;
}

.table [data-reach-tab-panel] {
  outline: none;
  padding-bottom: 1rem;
}

.table [data-reach-tabs] {
  position: relative;
  padding: 0 1rem;
}

.table-games .table .empty-games {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5rem 1rem;
}

.table-games .table .empty-games .empty-image {
  background-image: url("../../media/no_live_games.svg");
  width: 10rem;
  height: 10rem;
  background-size: cover;
  margin-bottom: 1rem;
}

.table-games .table .empty-games p {
  font-size: 1.5rem;
  line-height: 2rem;
}

.table-games .table .empty-games span {
  color: #f9bf1c;
}

.table-games h1 span {
  color: #f9bf1c;
}
