.live .game-status {
  color: #f9bf1c;
  opacity: 1;
}

.game-status {
  height: 2rem;
  padding: 0.25rem 0.5rem;
  margin-left: 1rem;
  font-size: 0.8rem;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  border: 1px solid #f9bf1c;
  color: #f9bf1c;
  border-radius: 15px;
  opacity: 0;
}
