.h2h-match {
  display: flex;
  border-radius: 10px;
  margin-bottom: 0.5rem;
  padding: 1rem 0;
  cursor: pointer;
}

.h2h-match:nth-child(2n) {
  background: #dbdcdc;
  border-radius: 10px;
}

.h2h-match:last-child {
  margin-bottom: 0;
}

.h2h-match .game-date {
  display: flex;
  align-items: center;
  width: 5.75rem;
  padding-left: 1rem;
  font-size: 0.9rem;
}

.h2h-match .game-competition {
  width: 10rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.h2h-match .game-competition p {
  font-size: 0.9rem;
  line-height: 1.25rem;
  text-align: center;
}

.h2h-match .game-details {
  width: calc(100% - 15.75rem);
}

@media (max-width: 1199px) {
  .h2h-match {
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 0.5rem;
  }
  .h2h-match .game-details {
    padding: 0 0.5rem;
  }
}

@media (max-width: 500px) {
  .h2h-match .game-competition {
    width: calc(100% - 10.75rem);
    flex-direction: row;
    flex-wrap: wrap;
    padding-left: 1rem;
  }
  .h2h-match .game-competition p:first-child {
    margin-right: 0.5rem;
  }
  .h2h-match .game-details {
    width: 100%;
  }
}
