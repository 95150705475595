.page-single-channel .title {
  margin-bottom: 2rem;
}

.page-single-channel h1 {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.page-single-channel h1 span {
  color: #f9bf1c;
}
.page-single-channel h1 .tv-icon {
  display: block;
  background-image: url("../../media/icons/tv_yellow.svg");
  background-repeat: no-repeat;
  background-position: center center;
  width: 3rem;
  height: 3rem;
  margin-right: 0.5rem;
}

.page-single-channel .table {
  background: #3f4344;
  box-shadow: 0px 0px 30px -9px rgba(0, 0, 0, 0.55);
  border-radius: 10px;
  min-height: 10rem;
  margin-bottom: 4rem;
}
.page-single-channel .table.empty-widget {
  background: transparent;
  box-shadow: none;
  border: 4px dashed rgba(255, 255, 255, 0.15);
  color: rgba(255, 255, 255, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-single-channel .table.empty-widget p {
  font-size: 1.2rem;
  line-height: 1.75rem;
}

.page-single-channel .table .tab-list {
  display: flex;
  border-bottom: 1px solid #ffffff40;
  height: 4.5rem;
}

.page-single-channel .table .tab-list .tab-selection {
  border: none;
  background: transparent;
  color: #fff;
  border-bottom: 2px solid transparent;
  padding: 1rem;
  opacity: 0.2;
  display: flex;
  align-items: center;
  outline: none;
  cursor: pointer;
}

.page-single-channel .table .tab-list .tab-selection .live-counter {
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  background: #f9bf1c;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.5rem;
}

.page-single-channel .table .tab-list .tab-selection b {
  font-size: 0.8rem;
  color: #3f4344;
}

.page-single-channel .table .tab-list [data-selected] {
  border-bottom: 2px solid #f9bf1c;
  opacity: 1;
}

.page-single-channel .table [data-reach-tab-panel] {
  outline: none;
  padding-bottom: 1rem;
}

.page-single-channel .table [data-reach-tabs] {
  position: relative;
  padding: 0 1rem;
}

.page-single-channel .bottom-cell {
  display: flex;
}

.page-single-channel .bottom-cell h1 {
  margin-bottom: 1rem;
}

.page-single-channel .live-stream-list-box {
  width: calc(40% - 1rem);
  margin-bottom: 4rem;
  margin-right: 2rem;
}

.page-single-channel .top-games-wrapper {
  width: calc(60% - 1rem);
  margin-bottom: 4rem;
}

.page-single-channel .television-list {
  display: flex;
  flex-wrap: wrap;
}

.page-single-channel .endTexts p{
  padding-top: 1rem;
}

.page-single-channel .television-list .television-channel {
  min-width: calc(50% - 0.5rem);
}

@media (min-width: 768px) {
  .page-single-channel .television-list .television-channel:nth-child(2n) {
    margin-right: 0;
  }

  .page-single-channel .television-list .television-channel:nth-child(4n) {
    min-width: calc(50% - 0.5rem);
  }
}

@media (max-width: 1199px) {
  .page-single-channel .bottom-cell {
    flex-direction: column;
  }

  .page-single-channel .live-stream-list-box,
  .page-single-channel .top-games-wrapper {
    width: 100%;
    margin-right: 0;
  }
}

.span-space {
  display: contents;
}
