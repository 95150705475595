.page-games h2,
.page-games h1 {
  text-align: center;
  margin-bottom: 1rem;
}

.page-games h2 span,
.page-games h1 span {
  color: #f9bf1c;
}

.page-games .sub-title {
  text-align: center;
  margin-bottom: 2rem;
  font-size: 1.25rem;
}

.page-games .home-page-texts {
  margin-top: 2rem;
}

.page-games .endTexts p{
  padding-top: 1rem;
}
