.page-competition .title {
  margin-bottom: 2rem;
}
.page-competition h1 {
  text-align: left;
}

.page-competition h1.bottom-h1 {
  margin-bottom: 2rem;
}

.page-competition h1 span {
  color: #f9bf1c;
}

.page-competition .favorite-icon {
  width: 2rem;
  height: 2rem;
  align-self: center;
  margin-left: 0.5rem;
  border: none;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: transparent;
  background-image: url("../../media/icons/star-outline.svg");
  outline: none;
  cursor: pointer;
}

.page-competition .favorite-icon.active {
  background-image: url("../../media/icons/star.svg");
}

.page-competition .endTexts p{
  padding-top: 1rem;
}
