.livestream {
  display: flex;
  height: 2.75rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  background-color: #151516;
  border-radius: 10px;
  width: calc(50% - 0.5rem);
}

.livestream:nth-child(2n) {
  margin-right: 0;
}

.livestream .livestream-icon {
  flex: 1;
  align-self: center;
}

.livestream .livestream-play {
  background-color: #f9bf1c;
  margin-right: -1px;
  width: 2.75rem;
  border-radius: 10px;
  background-image: url("../../../media//icons/play.svg");
  background-size: 30%;
  background-repeat: no-repeat;
  background-position: center center;
}

.livestream_nextgames {
  display: flex;
}
