.table-competition-game-row .television,
.television-list .television,
.television {
  align-items: center;
  background-color: transparent;
  margin-bottom: 0.25rem;
  margin-right: 1rem;
  cursor: pointer;
  max-width: 6rem;
}

.television-list .television:last-child {
  margin-right: 0;
}

.television span {
  color: #151516;
}

.television .television-icon {
  min-width: 1.25rem;
  height: 1.5rem;
  margin-right: 0.5rem;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../../media/icons/tv_yellow.svg");
}
