.other-tv {
  width: calc(50% - 1rem);
  margin-right: 2rem;
  border-radius: 10px;
  display: flex;
  flex: 1;
  flex-direction: column;
}

.other-tv.empty-widget {
  min-height: 20rem;
  border: 4px dashed rgba(255, 255, 255, 0.15);
  color: rgba(255, 255, 255, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
}

.other-tv.empty-widget p {
  font-size: 1.2rem;
  line-height: 1.75rem;
}

.other-tv .sub-title {
  display: flex;
  align-items: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #151516;
  color: #fff;
  padding: 0.5rem 1rem;
}

.other-tv .sub-title .television-icon {
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../media/icons/tv_yellow.svg");
}

.other-tv .sub-title span {
  margin-right: 0.5rem;
}

.other-tv .container {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 1rem 1rem;
  color: #151516;
  background-color: #fff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.other-tv .container .warning {
  padding: 0.75rem 1rem;
  margin-top: auto;
}
.other-tv .container .warning span {
  color: #f9bf1c;
}

@media (max-width: 1199px) {
  .other-tv {
    width: 100%;
    margin-right: 0;
    margin-bottom: 2rem;
  }
}
