.top-games {
  margin-bottom: 3rem;
}

.top-games .swiper-pagination-clickable .swiper-pagination-bullet {
  width: 1rem;
  height: 1rem;
  background-color: white;
  opacity: 0.2;
}

.top-games .swiper-pagination-clickable .swiper-pagination-bullet-active {
  background-color: #f9bf1c;
  opacity: 1;
}

@media (max-width: 768px) {
  .top-games {
    padding: 0;
  }
}
