.competition-table {
  min-height: 25rem;
  background: #3f4344;
  box-shadow: 0px 0px 30px -9px rgba(0, 0, 0, 0.55);
  border-radius: 10px;
  padding: 1rem;
  margin-bottom: 4rem;
}

.competition-table .region.close .competiton-list {
  display: none;
}

.competition-table .country:last-child {
  border: none;
}

.competition-table .empty-games {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5rem 1rem;
}

.competition-table .empty-games .empty-image {
  background-image: url("../../media/no_live_games.svg");
  width: 10rem;
  height: 10rem;
  background-size: cover;
  margin-bottom: 1rem;
}

.competition-table .empty-games p {
  font-size: 1.5rem;
  line-height: 2rem;
}
