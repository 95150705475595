footer {
  margin: 0 auto;
  max-width: 1190px;
}
footer .semi-footer {
  height: auto;
  border-bottom: 0.5px solid #56575b;
  padding: 7rem 0 3.5rem;
}

footer .semi-footer p,
footer .semi-footer b {
  opacity: 0.2;
  font-size: 1rem;
}

footer .footer-links {
  padding: 0;
  height: 5rem;
  display: flex;
  align-items: center;
}

footer .footer-links ul {
  list-style: none;
  display: inline-flex;
  float: left;
  padding: 0;
  margin: 0;
}

footer .footer-links li p {
  color: white;
  opacity: 0.2;
  text-decoration: none;
  font-weight: 300;
  font-size: 1rem;
  padding-right: 2rem;
}

footer .footer-links li p:hover {
  color: #f9bf1c;
  font-weight: 300;
  opacity: 1;
  cursor: pointer;
}

footer .footer-copyright {
  width: 30%;
  float: right;
  text-align: right;
}

footer .footer-copyright span {
  color: white;
  opacity: 0.2;
}

footer .footer-links-list {
  width: 70%;
  float: left;
}

footer .footer-copyright b {
  color: #f9bf1c;
  margin-right: 0.5rem;
}

@media (max-width: 1199px) {
  footer {
    padding: 0 1rem;
  }
  footer .footer-links-list {
    width: 100%;
    float: none;
    height: auto;
    padding: 1rem 0 0;
    display: flex;
    flex-flow: column-reverse;
    align-items: center;
  }
  footer .footer-copyright {
    width: 100%;
    float: none;
    text-align: center;
    padding: 1rem 0;
  }
  footer .footer-links {
    padding: 0;
    height: auto;
    display: block;
    align-items: center;
  }
  footer .footer-links ul {
    list-style: none;
    display: block;
    text-align: center;
  }
  footer .footer-links ul li {
    padding: 0 0 1rem 0;
  }
  footer .footer-links li a {
    font-size: 1rem;
    padding: 0;
  }
}

@media (max-width: 768px) {
  footer .semi-footer {
    padding-bottom: 1rem;
  }
  footer .semi-footer p,
  footer .semi-footer b {
    margin: 0;
  }
}
