.date-selector {
  display: flex;
  background-color: #f9bf1c;
  color: #151516;
  height: 3rem;
  border: none;
  float: right;
  font-size: 1rem;
  border-radius: 10px;
  position: absolute;
  right: 1rem;
  top: 0.65rem;
}

.date-selector .arrow-icon {
  width: 1rem;
  padding: 0 1rem;
  background-size: 30%;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.date-selector .arrow-icon.date-selector-left {
  background-image: url("../../../media/icons/left-arrow.svg");
}

.date-selector .arrow-icon.date-selector-right {
  background-image: url("../../../media/icons/right-arrow.svg");
}

.date-selector .calendar-date {
  background: transparent;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  font-size: 1rem;
  padding: 0 1rem;
  cursor: pointer;
}

.date-selector .calendar-date .calendar-icon {
  background: url("../../../media/icons/calendar.svg");
  width: 1rem;
  height: 1rem;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: transparent;
  border: none;
  outline: none;
  margin-right: 0.5rem;
}

.date-selector .date-picker {
  position: absolute;
  bottom: -10px;
  right: 0;
  transform: translate(0, 100%);
  background-color: #f9bf1c;
  z-index: 50;
  border-radius: 10px;
}

.date-selector .date-picker-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
}

@media (max-width: 768px) {
  .date-selector .calendar-date {
    padding: 0;
  }
}
