.game-row-spacer {
  flex: 1;
}

.livestream-list {
  display: flex;
  align-items: center;
}

.livestream-list .livestream {
  width: 9.5rem;
  margin: 0 1rem 0 0;
}

.television {
  margin-bottom: 0;
}

.television span {
  color: #fff;
}
