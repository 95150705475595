.menu {
  display: flex;
  justify-content: flex-end;
  flex: 1;
  padding-right: 1rem;
  margin: 0;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.menu .menu-list {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  list-style: none;
  margin: 0;
  padding: 0;
}

.menu .menu-list .menu-list-item {
  display: flex;
  align-items: center;
  opacity: 0.2;
  color: white;
  padding: 0 1.5rem;
  font-weight: bold;
  text-transform: uppercase;
}

.menu .menu-list .menu-list-item:hover {
  cursor: pointer;
  opacity: 1;
}

.menu .menu-list .menu-list-item b {
  margin: 0;
}

.menu .menu-list .menu-list-item.active {
  border-bottom: 3px solid #f9bf1c;
  opacity: 1;
}

.menu .menu-list-icon {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 1rem;
  height: 1.5rem;
  margin-right: 0.5rem;
}

.menu .cup-icon {
  background-image: url("../../../media/icons/cup_white.svg");
}

.menu .tv-icon {
  background-image: url("../../../media/icons/tv_white.svg");
}

.menu .stadium-icon {
  background-image: url("../../../media/icons/stadium_white.svg");
}

.menu .account-icon {
  background-image: url("../../../media/icons/account_white.svg");
}

@media (max-width: 1199px) {
  .menu {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    background-color: #0a0b0c;
    padding: 6.5rem 1rem;
    z-index: 19;
  }
  .menu.menu-closed {
    left: -220px;
  }
  .menu .menu-list {
    flex-direction: column;
    justify-content: flex-start;
  }
  .menu .menu-list .menu-list-item {
    padding: 1rem;
    border-radius: 10px;
  }
  .menu .menu-list .menu-list-item.active {
    color: #141619;
    border: none;
    opacity: 1;
    background-color: #f9bf1c;
  }
  .menu .menu-list .menu-list-item.active .stadium-icon {
    background-image: url("../../../media/icons/stadium_black.svg");
  }
  .menu .menu-list .menu-list-item.active .cup-icon {
    background-image: url("../../../media/icons/cup_black.svg");
  }
  .menu .menu-list .menu-list-item.active .tv-icon {
    background-image: url("../../../media/icons/tv_black.svg");
  }
  .menu .menu-list .menu-list-item.active .account-icon {
    background-image: url("../../../media/icons/account_black.svg");
  }
}
