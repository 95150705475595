@import url("https://fonts.googleapis.com/css?family=Ubuntu:300,400,400i,500,700&display=swap");

html {
  font-family: "Ubuntu", sans-serif;
  font-size: 14px;
}

p {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: normal;
  margin: 0;
}

a {
  line-height: 1.2em;
  margin: 0;
  text-decoration: none;
  color: #161615;
}

h6 {
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: normal;
  margin: 0;
}

h5 {
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: normal;
  margin: 0;
}

h4 {
  font-size: 1.75rem;
  line-height: 2.25rem;
  font-weight: normal;
  margin: 0;
}

h3 {
  font-size: 2rem;
  line-height: 2.5rem;
  font-weight: normal;
  margin: 0;
}

h2 {
  font-size: 2.25rem;
  line-height: 2.75rem;
  font-weight: normal;
  margin: 0;
}

h1 {
  font-size: 2.5rem;
  line-height: 3rem;
  font-weight: normal;
  margin: 0;
}

button {
  font-family: inherit;
  font-weight: inherit;
}

@media (max-width: 768px) {
  html {
    font-size: 12px;
  }
}
