.livestream-channel {
  width: calc(25% - 0.75rem);
  height: 5rem;
  background-color: #3f4344;
  box-shadow: 0px 0px 30px -9px rgba(0, 0, 0, 0.55);
  border-radius: 10px;
  display: flex;
  align-items: center;
  margin: 1rem 1rem 0 0;
  cursor: pointer;
}

.livestream-channel:nth-child(4n) {
  margin-right: 0;
}

.liveStream-WithOut-Link {
  width: 100%;
  height: 100%;
  padding-top: 1.6rem;
}

@media (max-width: 768px) {
  .livestream-channel {
    width: 100%;
    margin-right: 0;
  }
}
