* {
  box-sizing: border-box;
}

body {
  margin: 0;
  background-color: #2e3032;
  color: #fff;
}

.default-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.main-container {
  flex: 1;
  width: 100%;
  max-width: 1190px;
  margin: 0 auto;
  padding-top: 3rem;
}

body .swiper-container {
  padding-bottom: 4rem;
}

.d-none {
  display: none !important;
}

button::-moz-focus-inner {
  border: 0;
}

/******************** CUSTOM ACADEMIA *********************/

body .bm_style.ico21,
.bm_style .ico21 {
  display: inline-block;
  width: 89px;
  height: 21px;
  background: url("https://academia.r.worldssl.net/system/cms/themes/shared/img/layout/bookmakers/bm_21x89@2x.png")
    no-repeat;
  background-size: 89px auto;
  vertical-align: middle;
}

body .bookmaker_small_icon {
  display: inline-block;
  width: 24px;
  height: 16px;
  background: url("https://academia.r.worldssl.net/system/cms/themes/shared/img/layout/bookmakers/bm_16x24@2x.png")
    no-repeat;
  background-size: 24px auto;
  vertical-align: middle;
  margin-left: 10%;
}

body .betfair-background {
  background-color: #ffb80c;
}

body .bet365-background {
  background-color: #35795d;
}

body .betpt-background {
  background-color: #000;
}

body .betano-background {
  background-color: #ff2a00;
}

body .melbet-background {
  background-color: #363b3f;
}

body ._1xbet-background {
  background-color: #2a5681;
}

@media (min-width: 1199px) {
  .d-xl-none {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .main-container {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .d-xs-none {
    display: none !important;
  }
}
