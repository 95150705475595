.competiton-list .competition {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: 100;
  cursor: pointer;
  height: 3rem;
}

.competition .icon-cup {
  width: 1.5rem;
  height: 1.5rem;
  align-self: center;
  border: none;
  margin: 0 0.5rem;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: transparent;
  background-image: url("../../../media/icons/cup_yellow.svg");
  outline: none;
  cursor: pointer;
}
.competition .competition-name {
  align-self: center;
  cursor: pointer;
}

.competition .favorite-icon {
  width: 1.5rem;
  height: 1.5rem;
  align-self: center;
  margin-left: 0.5rem;
  border: none;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: transparent;
  background-image: url("../../../media/icons/star-outline.svg");
  outline: none;
  cursor: pointer;
}

.competition .favorite-icon.active {
  background-image: url("../../../media/icons/star.svg");
}

/* .competition .competition-spacer {
  flex: 1;
  cursor: pointer;
} */
