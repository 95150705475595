.table-competition {
  margin-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #ffffff40;
}
.table-competition:last-child {
  border: none;
}

.table-competition .table-competition-top {
  display: flex;
  height: 3.25rem;
}

.table-competition .table-competition-top .competition-description {
  flex: 1;
  display: flex;
  font-size: 1.25rem;
  font-weight: 100;
}

.table-competition
  .table-competition-top
  .competition-description
  .competition-icon,
.table-competition
  .table-competition-top
  .competition-description
  .competition-name {
  margin-right: 0.5rem;
  align-self: center;
  cursor: pointer;
}

.table-competition
  .table-competition-top
  .competition-description
  .favorite-icon {
  width: 1.5rem;
  height: 1.5rem;
  border: none;
  align-self: center;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: transparent;
  background-image: url("../../../media/icons/star-outline.svg");
  outline: none;
  cursor: pointer;
}

.table-competition .table-competition-top .favorite-icon.active {
  background-image: url("../../../media/icons/star.svg");
}

.table-competition
  .table-competition-top
  .competition-description
  .competition-spacer {
  flex: 1;
  cursor: pointer;
}

.table-competition .table-competition-top .expand-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 1rem;
  padding-right: 1.25rem;
  border-left: 1px solid #ffffff40;
  cursor: pointer;
}

.table-competition .table-competition-top .expand-icon button {
  width: 1.5rem;
  height: 1.5rem;
  background-size: 1rem;
  outline: none;
  border: none;
  opacity: 0.2;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: transparent;
  background-image: url("../../../media/icons/arrow-up-white.svg");
}

.table-competition .table-competition-top .expand-icon.open button {
  background-image: url("../../../media/icons/arrow-up-yellow.svg");
  opacity: 1;
}

.table-competition-games {
  padding-bottom: 0.5rem;
}

@media(max-width: 767px){
  .table-competition .table-competition-top .competition-description {
    font-size: 1.1rem;
  }

  .table-competition .table-competition-top .competition-description .competition-name{
    max-width: 70%;
  }
  .table-competition .table-competition-top .competition-description .favorite-icon{
    position: absolute;
    right: 5.5rem;
  }

}