.game {
  background-color: transparent;
  border-radius: 15px;
  background: #fff;
}

.game .top-game-competition {
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;
  background-color: #151516;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  cursor: pointer;
  justify-content: flex-start;
}

.game .game-description {
  display: flex;
  align-items: center;
  padding: 1rem;
  text-align: center;
  background: #fff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  cursor: pointer;
}

.game .top-game-competition .competition-icon {
  margin-right: 0.5rem;
}

.game .game-description .game-home-team,
.game .game-description .game-away-team {
  width: 30%;
  padding: 0 1rem;
}

.game .game-description .game-home-team p,
.game .game-description .game-away-team p {
  font-weight: 600;
  color: #151516;
  font-size: 1rem;
  line-height: 1.25rem;
}

.game .game-description .game-team-icon {
  height: 7rem;
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}

.game .game-description .game-details {
  width: 40%;
  height: auto;
  color: #151516;
  flex-direction: column;
  align-items: center;
}

.game .game-description .game-details.live .live-status {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.game .game-description .game-details.live .live-status > p {
  font-weight: 500;
  background-color: red;
  color: #fff;
  padding: 0 0.5rem;
  border-radius: 10px;
  margin-bottom: 0.5rem;
}

.game .separate {
  border-top: 0.5px solid #151516;
  opacity: 0.2;
  padding: 0.25rem 0;
}

.game .game-livestream,
.game .game-television {
  background-color: white;
  padding: 0 1rem;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.game .game-television {
  padding: 0 1rem 0.75rem;
}

.game .game-livestream p,
.game .game-television p {
  color: #151516;
  margin-bottom: 0.5rem;
  width: 100%;
  position: relative;
}

.game .game-television .television-list,
.game .game-livestream .livestream-list {
  display: inline-block;
}

.game .livestream-list .livestream,
.game .television-list .television {
  display: inline-flex;
}

.game .game-livestream,
.game .game-television {
  text-align: center;
}

.game .game-livestream.empty .livestream-list,
.game .game-television.empty .television-list {
  padding: 0.5rem 1rem;
  text-align: center;
  display: block;
}

.game .game-livestream.empty .livestream-list p,
.game .game-television.empty .television-list p {
  text-align: center;
  font-style: italic;
  opacity: 0.5;
}

.game .game-livestream .livestream-list .livestream {
  margin-bottom: 0.5rem;
}
.game .game-livestream .livestream-list .livestream:nth-child(2n) {
  margin-right: 0;
}

.game .game-television .television-list .television span {
  color: #151516;
}
