.television-list .television-channel,
.television-list .television-channel,
.television-channel {
  min-width: calc(25% - 0.75rem);
  max-width: calc(25% - 0.75rem);
  min-height: 5rem;
  padding: 1rem;
  background-color: #3f4344;
  box-shadow: 0px 0px 30px -9px rgba(0, 0, 0, 0.55);
  border-radius: 10px;
  display: flex;
  align-items: center;
  margin: 1rem 1rem 0 0;
  cursor: pointer;
}

.television-list .television-channel {
  justify-content: flex-start;
}

.television-channel:nth-child(4n) {
  margin-right: 0;
}

.television-channel .tv-icon {
  background-image: url("../../../media/icons/tv_yellow.svg");
  background-repeat: no-repeat;
  background-position: center center;
  min-width: 2rem;
  min-height: 2rem;
  margin-right: 1rem;
}

.television-channel p {
  font-size: 1rem;
}

@media (max-width: 768px) {
  .television-list .television-channel,
  .television-channel {
    width: 100%;
    max-width: unset;
    margin-right: 0;
  }
}
