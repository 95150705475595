.page-privacy-policy .main-container h1 {
  color: #f9bf1c;
  margin-bottom: 1rem;
}
.page-privacy-policy .main-container h6 {
  color: #f9bf1c;
}

.page-privacy-policy .main-container p {
  margin-bottom: 1rem;
}
.page-privacy-policy .main-container p img {
  margin-bottom: -4px;
  width: 250px;
  height: 20px;
}
