.region {
  margin-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #ffffff40;
}

.region:last-child {
  border: none;
}

.region .region-top {
  height: 3.25rem;
  display: flex;
  cursor: pointer;
}

.region .region-description {
  flex: 1 1;
  display: flex;
  font-size: 1.25rem;
  font-weight: 100;
  padding-right: 1rem;
}

.region .region-description .region-icon,
.region .region-description .region-name {
  margin-right: 0.5rem;
  align-self: center;
}

.region .region-top small {
  font-size: 0.9rem;
  font-weight: bold;
  color: #f9bf1c;
  align-self: center;
}

.region .region-top .region-spacer {
  flex: 1 1;
  cursor: pointer;
}

.region .region-top .expand-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 1rem;
  padding-right: 1.25rem;
  border-left: 1px solid #ffffff40;
  cursor: pointer;
}

.region .region-top .expand-icon.open button {
  background-image: url("../../../media/icons/arrow-up-yellow.svg");
  opacity: 1;
}

.region .region-top .expand-icon button {
  width: 1.5rem;
  height: 1.5rem;
  background-size: 1rem;
  outline: none;
  border: none;
  opacity: 0.2;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: transparent;
  background-image: url("../../../media/icons/arrow-up-white.svg");
}

.region .special-flag {
  background-repeat: no-repeat;
  background-position: center center;
  background-color: transparent;
  width: 20px;
  height: 20px;
  float: left;
  background-size: 20px;
  padding: 0;
  background-image: url("../../../media/icons/world.svg");
}

.region .competiton-list {
  display: none;
}

.region.open .competiton-list {
  display: block;
}

.region .competiton-list .competition:nth-child(2n-1) {
  background: #4b4f50;
  border-radius: 10px;
  margin: 0.5rem 0;
  padding-right: 1rem;
}
