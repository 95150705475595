.other-tv-row {
  display: flex;
  border-radius: 10px;
  margin-bottom: 0.5rem;
  padding: 0.5rem 0;
}

.other-tv-row:nth-child(2n) {
  background: #dbdcdc;
}

.other-tv-row:last-child {
  margin-bottom: 0;
}

.other-tv-row p {
  font-size: 0.9rem;
}

.other-tv-row .left-cell {
  display: flex;
  align-items: center;
  width: 40%;
  padding-left: 1rem;
}

.other-tv-row .left-cell span {
  margin-right: 0.5rem;
}

.other-tv-row .right-cell {
  width: 60%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.other-tv-row .right-cell span {
  font-size: 0.9rem;
  margin-right: 0.5rem;
}
