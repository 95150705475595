.page-all-competitions .title {
  margin-bottom: 2rem;
}

.page-all-competitions h1 {
  text-align: left;
}

.page-all-competitions h1 span {
  text-transform: capitalize;
  color: #f9bf1c;
}

.page-all-competitions .endTexts p{
  padding-top: 1rem;
}
