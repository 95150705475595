.more-game-info {
  display: flex;
}

.more-game-info .right-cell {
  margin-right: 4rem;
  flex-direction: column;
}

.more-game-info .right-cell .livestream-container,
.more-game-info .right-cell .television-container {
  border-top: 1px solid #ffffff40;
  padding: 1rem 0 0;
}

.more-game-info .right-cell .livestream-container p,
.more-game-info .right-cell .television-container p {
  margin-bottom: 0.5rem;
}

.more-game-info .right-cell .livestream-container .livestream-list,
.more-game-info .right-cell .television-container .television-list {
  display: flex;
  flex-wrap: wrap;
}

.more-game-info .right-cell .livestream-container .livestream,
.more-game-info .right-cell .television-container .television {
  margin-bottom: 1rem;
}

.more-game-info .right-cell .more-sugestion {
  border: 1px solid #f9bf1c;
  background-color: transparent;
  color: #f9bf1c;
  padding: 0.5rem 1rem;
  margin-bottom: 0.5rem;
  border-radius: 15px;
  outline: none;
  cursor: pointer;
}

.more-game-info .right-cell .more-sugestion:hover {
  background-color: #f9bf1c;
  color: #151519;
}

@media (max-width: 767px) {
  .more-game-info {
    flex-direction: column;
  }
  .more-game-info .right-cell {
    width: 100%;
    margin: 0 0 1rem 0;
  }
  .more-game-info .right-cell .livestream-container,
  .more-game-info .right-cell .television-container {
    padding: 1rem 1rem 0;
  }
  .more-game-info .right-cell .livestream-container .livestream {
    margin-bottom: 1rem;
  }
  .more-game-info .right-cell .more-sugestion {
    margin: 0 1rem;
  }
}

@media (max-width: 768px) {
  .more-game-info .right-cell .livestream-container {
    padding-bottom: 0;
  }
  .more-game-info .livestream-list {
    flex-wrap: wrap;
  }
  .more-game-info
    .right-cell
    .livestream-container
    .livestream-list
    .livestream {
    /* width: calc(50% - 0.5rem); */
    flex: unset;
    margin-right: 1rem;
    margin-bottom: 1rem;
  }
  .more-game-info
    .right-cell
    .livestream-container
    .livestream-list
    .livestream:nth-child(2n) {
    margin-right: 0;
  }
}
