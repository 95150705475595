.next-games .next-available-game,
.next-available-game {
  display: flex;
  border-radius: 10px;
  padding: 1rem 0;
  cursor: pointer;
  margin-bottom: 0.5rem;
}

.next-available-game:nth-child(2n) {
  background: #dbdcdc;
  border-radius: 10px;
}

.next-available-game:last-child {
  margin-bottom: 0;
}
.next-available-game .top-cell {
  display: flex;
  width: 60%;
}

.next-available-game .game-date {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 1rem;
  flex-shrink: 0;
}

.next-available-game .game-date p {
  font-size: 0.9rem;
  line-height: 1.15rem;
}

.next-available-game .game-logos {
  width: 7.5rem;
  display: flex;
  align-items: center;
  margin: 0 1rem;
  flex-shrink: 0;
  flex-basis: 25%;
}

.next-available-game .game-logos .game-team-icon {
  height: 2.5rem;
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}

.next-available-game .match-teams {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  padding-right: 1rem;
}

.next-available-game .television-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex: 1;
}

.next-available-game .television span {
  color: #000;
}

.next-available-game .left {
  left: 0;
  width: 30%;
  padding: 10px 10px 0 0;
}

.next-available-game .left .dual_live{
  margin-top: 0.3rem;
}
.next-available-game .right {
  right: 0;
  width: 70%;
  padding: 10px 10px 0 0;
}

.next-available-game .home-team {
  padding-left: 2rem;
  flex-direction: column;
  flex-grow: 2;
  flex-shrink: 2;
  flex-basis: 30%;
  align-items: flex-end;
  max-width: 7rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.next-available-game .away-team {
  flex-direction: column;
  flex-grow: 2;
  flex-shrink: 2;
  flex-basis: 30%;
  align-items: flex-start;
  max-width: 7rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 1199px){

  .next-available-game .top-cell {
      justify-content: flex-start;
  }

  .next-available-game .home-team {
    padding-left: 8rem;
    flex-basis: 0%;
    max-width: 18rem;
  }

  .next-available-game .game-logos {
    flex-basis: 15%;
  }

  .next-available-game .away-team {
    flex-basis: 0%;
    max-width: 11rem;
  }
}

@media (max-width: 767px) {
  .next-games .next-available-game,
  .next-available-game {
    flex-direction: column;
    width: 100%;
  }


  .next-available-game .home-team {
    padding-left: 2rem;
    flex-basis: 0%;
    max-width: 8rem;
  }

  .next-available-game .game-logos {
    flex-basis: 25%;
  }

  .next-available-game .away-team {
    flex-basis: 0%;
    max-width: 8rem;
  }

  .next-available-game .game-details {
    width: 100%;
  }

  .next-available-game .top-cell {
    width: 100%;
  }
  .next-available-game .television-list {
    justify-content: center;
    margin-top: 5%;
    text-align: center;
  }
  .next-available-game .left .dual_live{
    display: inline-block;
    width: 40%; 
    margin-top: 0.2rem;
  }
  
}

@media (max-width: 320px) {
  .next-available-game .game-logos {
    margin: 0 0rem;
  }
}
