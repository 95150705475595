.page-all-channels .live-stream-list-box {
  margin-bottom: 6rem;
}

.page-all-channels .live-stream-list-box h1,
.page-all-channels .live-stream-list-box h2 {
  margin-bottom: 1rem;
  color: #f9bf1c;
}

.page-all-channels .live-stream-list-box h1 span,
.page-all-channels .live-stream-list-box h2 span {
  color: #fff;
}

.page-all-channels .live-stream-list,
.page-all-channels .television-list {
  display: flex;
  flex-wrap: wrap;
}

.page-all-channels .endTexts p{
  padding-top: 1rem;
}
.page-all-channels .endTexts h3{
  padding-top: 1rem;
}

@media (max-width: 768px) {
  .page-all-channels .live-stream-list-box {
    margin-bottom: 4rem;
  }
}
