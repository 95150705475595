.live .game-time {
  color: #f9bf1c;
  opacity: 1;
}

.game-time {
  padding: 0 1rem;
  width: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 0.9rem;
}

.game-time p {
  font-size: 0.8rem;
  text-align: center;
  line-height: 1rem;
  opacity: 0.5;
}
