.page-contacts h1 {
  color: #f9bf1c;
  margin-bottom: 1rem;
}

.page-contacts .main-container p {
  margin-bottom: 1rem;
}

.page-contacts .main-container p img {
  margin-bottom: -3px;
  width: 250px;
  height: 20px;
}
